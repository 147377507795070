<template>
    <AppMobile v-if="isMobile"></AppMobile>
    <AppDesktop v-else></AppDesktop>
</template>

<script setup lang="ts">
  import { inject, onMounted } from 'vue';
  import AppMobile from './AppMobile.vue';
  import AppDesktop from './AppDesktop.vue';
  import { AuthStore } from './stores/AuthStore';
  const isMobile = inject('isMobile')
  const authStore = AuthStore();

  onMounted(()=>{
    authStore.fetchUserData()
  })
</script>

<style scoped>
</style>
