<template>
    <ion-item>
        <ion-label>{{roadbooksStore.roadbooks.length}}</ion-label>
    </ion-item>
    <ion-row>
        <ion-col size="10">
            <div ref="mapContainer" :style="'height:'+height+'px; width:100%'">
                <l-map ref="map" v-model:zoom="parameters.zoom" v-model:center="parameters.center" 
                    :useGlobalLeaflet="parameters.useGlobalLeaflet" :options="parameters.options"
                >
            
                    <l-tile-layer :url="defaultMapLayer" layer-type="base" name="TripyMap3"></l-tile-layer>           
                
                    <l-layer-group v-for="roadbook of roadbooksStore.roadbooks">
                        <l-polyline v-if="roadbook.points && roadbook.points.length" :lat-lngs="roadbook.points" color="#3c9bab"/>
                    
                        <l-marker v-if="roadbook.points && roadbook.points.length"  :lat-lng="[roadbook.points[0][0], roadbook.points[0][1]]">
                            <l-icon :iconSize="[46, 60]" :iconAnchor="[31, 44]" :popupAnchor="[0,-40]" :options="{className:''}">
                                <img src="/start.svg" style="height:46px;width:60px;"/>
                            </l-icon>
                        </l-marker>
                    </l-layer-group>
                </l-map>
            </div>
        </ion-col>
        <ion-col size="2">
            <RoadbookCard v-for="roadbook of roadbooksStore.all"></RoadbookCard>
        </ion-col>
    </ion-row>
    <!-- <ion-fab vertical="bottom" horizontal="center" slot="fixed">
            <ion-item>
                <ion-button @click="" size="medium">
                    <ion-icon :icon="searchSharp" ></ion-icon>
                    {{ $t('Search') }}
                </ion-button>
                <ion-button @click="" size="medium">
                    <ion-icon slot="start" :icon="cogOutline"></ion-icon>
                    Options
                </ion-button>
                <ion-item>
                    <ion-label style="width:180px;">Style de route :</ion-label>
                    <ion-select value="peperoni">
                        <ion-select-option value="peperoni">
                        Sinueux</ion-select-option>
                        <ion-select-option value="hawaii">Rapide</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-buttons>
                    <ion-button @click="">
                        <ion-icon slot="icon-only" :icon="arrowUndoOutline" size="large"></ion-icon>
                    </ion-button>
                    <ion-button @click="">
                        <ion-icon slot="icon-only" :icon="arrowRedoOutline" size="large"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-button @click="" size="medium">
                    <ion-icon slot="icon-only" :icon="saveOutline"></ion-icon>
                    {{ $t('Save') }}
                </ion-button>
            </ion-item>
        </ion-fab> -->
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    /* Leaflet */
    import "leaflet"; // to use L object
    import "leaflet/dist/leaflet.css";
    /* Leaflet cluster */
    // import { MarkerClusterGroup } from 'leaflet.markercluster'
    // import 'leaflet.markercluster/dist/MarkerCluster.css'
    // import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

    import { LMap, LTileLayer, LMarker, LCircleMarker, LControlZoom, LPolyline, LLayerGroup, LIcon } from "@vue-leaflet/vue-leaflet";
    import { tileProviders, defaultMapLayer } from '@/libs/tileProviders'
    import MapTrace from './MapTrace.vue';
    import { add, addCircleOutline, arrowRedoOutline, arrowUndoOutline, cogOutline, saveOutline, searchSharp } from 'ionicons/icons';
    import RoadbookCard from './RoadbookCard.vue';

    const map = ref()
    const roadbooksStore = RoadbooksStore()
    const height = window.innerHeight - 150
    const parameters = {
                // TODO: local position if detected, Home country if not, else Europe center
                center: [46.66181748307193, 7.799474973236367], // centered on Europe by default
                zoom: 5,
                useGlobalLeaflet: false,
                options: {
                    zoomControl: false, 
                    zoomSnap:0.1, // Zoom precision ratio, default 1. Usefull to use fitBounds with padding precision
                    attributionControl: false // disable leaflet map attribution label
                },
    }

    
    onMounted(()=>{
        initMap()
    })
    
    function initMap(){
        // wait data available to finalize INIT
        if(! map.value.leafletObject) {
            setTimeout(function () {
                initMap()
            }, 100);
            return;
        }
        // var markers = new MarkerClusterGroup();
        // markers.addLayer(L.marker(new L.LatLng(0, 0)));
        // markers.addLayer(L.marker(new L.LatLng(0, 0)));
        // markers.addLayer(L.marker(new L.LatLng(0, 0)));
        // map.value.leafletObject.addLayer(markers);
    }

</script>

<style scoped>
.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    display: none !important;
    visibility: hidden !important;
}
</style>