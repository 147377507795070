<template>
    <ion-label>{{ address() }}</ion-label>   
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng,
        label,
        location
    })

    function latlng(){
        return [parseFloat(props.location.lat), parseFloat(props.location.lon)]
    }

    function label(){
        return props.location.name
    }

    function location(){
        return {
            coordinates: latlng(),
            address: address(),
            label: label()
        }
    }

    function address(){
        let address = []
        if(props.location.name) address.push(props.location.name)
        if(props.location.address.road && props.location.name != props.location.address.road) address.push(props.location.address.road)
        if(props.location.address.city && props.location.name != props.location.address.city) address.push(props.location.address.city)
        if(props.location.address.county && props.location.name != props.location.address.county) address.push(props.location.address.county)
        if(props.location.address.country && props.location.name != props.location.address.country) address.push(props.location.address.country)
        return address.join(', ')
    }
</script>
