<template>
    <ion-page>
        <ion-header v-if-mobile>
            <ion-toolbar class="ion-text-center">
                <BackNavButton slot="start"></BackNavButton>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true" class="ion-padding">
        <ion-row>
            <ion-col size-md="6" offset-md="3" size-sm="6" offset-sm="6" size-xs="12" class="markdown">
                <VueMarkdownIt :source="$t('confidentiality_text')" />
                <a>{{ tripyEmailContact }}</a>
            </ion-col>
        </ion-row>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { inject } from 'vue';
    import BackNavButton from '@/components/BackNavButton.vue';
    import { VueMarkdownIt } from '@f3ve/vue-markdown-it';

    const tripyEmailContact = inject('tripyEmailContact')
</script>

<style scoped>
</style>