<template>
    <div>
        <ion-modal ref="settingsModal" v-on:keyup.escape="dismiss" :can-dismiss="true">
            <ion-header>
                <ion-toolbar>
                    <ion-icon slot="start" src="/settings.svg" size="large" class="ion-margin-start"></ion-icon>
                    <ion-title>
                        {{ $t('Settings') }}
                    </ion-title>
                    <ion-buttons slot="end">
                        <ion-button @click="dismiss" size="large">
                            <ion-icon slot="end" :icon="close"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list>
                    <ion-list-header>
                        <ion-label color="primary">{{$t('Routing')}}</ion-label>
                    </ion-list-header>
                    <!-- <ion-item lines="none" button>
                        <ion-toggle justify="space-between" v-model="roadbookStore.options.display_poi">{{ $t('Display POIs') }}</ion-toggle>
                    </ion-item>
                    <ion-item lines="none" button>
                        <ion-label>{{$t('Avoid tollgates')}}</ion-label>
                        <ion-toggle></ion-toggle>
                    </ion-item>
                    <ion-item lines="none" button>
                        <ion-label>{{$t('Generate Tripy3 pictos')}}</ion-label>
                        <ion-toggle v-model="roadbookStore.options.generate_pictos"></ion-toggle>
                    </ion-item> -->
                    <ion-item lines="none" button>
                        <ion-toggle justify="space-between" v-model="roadbookStore.options.marker_number">{{ $t('Display waypoints number') }}</ion-toggle>
                    </ion-item>
                    <!-- <ion-item lines="none" button>
                        <ion-toggle justify="space-between" v-model="roadbookStore.options.locked_destination">{{ $t('Use destination as last step') }}</ion-toggle>
                    </ion-item> -->
                    <ion-list-header>
                        <ion-label color="primary">{{$t('Map')}}</ion-label>
                    </ion-list-header>
                    <ion-item lines="none">
                        <ion-select  label-placement="stacked" v-model="roadbookStore.options.map_layer_id" interface="popover">
                            <div slot="label">{{ $t('Base map') }}</div>
                            <ion-select-option v-for="layer of layers" :value="layer.id">{{$t(layer.name)}}</ion-select-option>
                        </ion-select>
                    </ion-item>
                    <ion-item lines="none" button>
                        <ion-toggle justify="space-between" v-model="roadbookStore.options.automatic_feet">{{ $t('Automatic fit') }}</ion-toggle>
                    </ion-item>
                    <ion-item lines="none" button>
                        <ion-toggle justify="space-between" v-model="roadbookStore.options.map_zoom_buttons">{{ $t('Display zoom buttons') }}</ion-toggle>
                    </ion-item>
                </ion-list>
            </ion-content>
        </ion-modal>
        <ion-fab slot="fixed" vertical="bottom" horizontal="end">
            <ion-fab-button @click="open()">
                <ion-icon src="/settings.svg" size="large"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    </div>
</template>

<script setup lang="ts">
    import { close } from 'ionicons/icons';
    import { computed, ref, watch } from 'vue';
    import { tileProviders } from '@/libs/tileProviders'
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { roadbookAPI } from '@/libs/backend'

    const emit = defineEmits<{
        (e: 'onDismiss'): void
    }>();
    
    defineExpose({
        dismiss,
        open,
    });
    
    // HTML Element refs
    const settingsModal = ref();
    // Variable refs
    const roadbookStore = roadbookEditStore();
    const layers = ref(tileProviders)

    watch(() => roadbookStore.options, (newValue, oldValue) => {
        if(roadbookStore.initialized && !roadbookStore.isNew()){ 
            // live update only for existing Roadbooks
            roadbookAPI.updateDisplayOptions(roadbookStore.id, roadbookStore.options)
        }
    }, { deep: true });


    function open(){
        settingsModal.value.$el.present();
    }

    function dismiss(){
        settingsModal.value.$el.dismiss();
        emit('onDismiss');
    }

</script>

<style scoped>
ion-modal {
    --max-height: 400px;
}
</style>