<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title><h1>{{ $t('Profile') }}</h1></ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content-auth-guard>
                <ion-card button router-link="/profile/devices">
                    <ion-item lines="none">
                        <MaterialSymbol slot="start" name="smart_screen"></MaterialSymbol>
                        <ion-card-title>{{$t('Devices')}}</ion-card-title>
                    </ion-item>
                </ion-card>
                <ion-card button router-link="/profile/parameters">
                    <ion-item lines="none">
                        <ion-icon slot="start" :icon="cogSharp"></ion-icon>
                        <ion-card-title>{{$t('Settings')}}</ion-card-title>
                    </ion-item>
                </ion-card>
                <ion-card button router-link="/profile/account">
                    <ion-item lines="none">
                        <ion-icon slot="start" :icon="person"></ion-icon>
                        <ion-card-title>{{$t('Account')}}</ion-card-title>
                    </ion-item>
                </ion-card>
                <ion-button @click="authStore.logout()" expand="block" fill="clear">
                    {{$t('Logout')}}
                </ion-button>
        </ion-content-auth-guard>
    </ion-page>
</template>

<script setup lang="ts">
import IonContentAuthGuard from '@/components/IonContentAuthGuard.vue';
import { AuthStore } from '@/stores/AuthStore';
import { MaterialSymbol } from '@dbetka/vue-material-symbols';
import { cogSharp, person } from 'ionicons/icons';
const authStore = AuthStore();
</script>

<style scoped>
    ion-card{
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>