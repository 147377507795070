import { defineStore } from 'pinia'
import { favoriteAPI } from '@/libs/backend'
import { i18n } from '@/main';
import { presentToast } from '@/libs/userExperience'

/* Sorage for Roabook edition on map pages */
export const favoritePlaceStore = defineStore("favoriteplace", {
    state: () => ({
      places: <any>[],
      processing: <boolean> false
    }),
    actions: {
      async fetch() {
        this.processing = true;
        try {
          this.places = await favoriteAPI.all();
        }
        catch (error) {
          presentToast(i18n.global.t('Favorites fetch error'), 'danger');
        }
        this.processing = false;
        return this.places;
      },

      init(){
        this.$reset();
        this.fetch();
      },

      async add(data:any){
          this.processing = true;
          let place = await favoriteAPI.create(data);
          await this.fetch();
          return place;
      },

      async remove(latLng:Array<number>){
        this.processing = true;
        await favoriteAPI.delete(`${latLng[0]},${latLng[1]}`);
        await this.fetch();
      },
      
      isFavoritePlace(latLng:Array<number>){
        for(let place of this.places){
            if(place.coordinates[0] == latLng[0] && place.coordinates[1] == latLng[1]){
                return true;
            }
        }
        return false;
      }
    },
})