<template>
    <ion-app class="main-app">
      <ion-router-outlet id="main-content" mode="ios" animated="false"></ion-router-outlet>

      <ion-tabs v-if="mobileTabs">
          <ion-router-outlet mode="ios" animated="false"></ion-router-outlet>

         <ion-tab-bar slot="bottom">
          <ion-tab-button tab="home" href="/dashboard">
            <MaterialSymbol name="home" class="tab-icon"></MaterialSymbol>
            {{$t('Home')}}
          </ion-tab-button>
          <ion-tab-button tab="community" href="/community">
            <MaterialSymbol name="signpost" class="tab-icon"></MaterialSymbol>
            {{$t('Explore')}}
          </ion-tab-button>
          <div style="width:100px">
          </div>
          <ion-tab-button tab="roadbooks" href="/roadbooks">
            <MaterialSymbol name="route" class="tab-icon"></MaterialSymbol>
            {{$t('Roadbooks')}}
          </ion-tab-button>
          <ion-tab-button tab="profile" href="/profile">
            <MaterialSymbol name="account_circle" class="tab-icon"></MaterialSymbol>
            {{ $t('Profile') }}
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>

      <ion-fab v-show="mobileTabs" vertical="bottom" horizontal="center" slot="fixed">
        <ion-fab-button ref="tripButton" color="primary" @click="newTripModal.open()"  style="display: flex" class="trip-icon">
            <ion-row>
              <ion-col size="12" class="ion-no-padding">
                <MaterialSymbol name="add_location_alt" class="tab-icon"></MaterialSymbol>
              </ion-col>
              <ion-col size="12" class="ion-no-padding">
                <ion-text>{{$t('Trip')}}</ion-text>
              </ion-col>
            </ion-row>
        </ion-fab-button>
      </ion-fab>

      <TripModal ref="newTripModal"></TripModal>
    </ion-app>
</template>
  
  
<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import TripModal from './components/TripModal.vue';
    import router  from '@/router';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { useRoute } from 'vue-router';
    import { createAnimation } from '@ionic/vue';
    import type { Animation } from '@ionic/vue';
    
    const route = useRoute();
    const mobileTabs:any = ref(false);
    const newTripModal = ref();
    const tripButton = ref();

    let tripAnimation: Animation;
    let tripAnimationDone:boolean = false;

    onMounted(async()=>{
        mobileTabs.value = route.meta.mobiletabs

        tripAnimation = createAnimation()
          .addElement(tripButton.value.$el)
          .duration(100)
          .iterations(1)
          .keyframes([
            { offset: 0, transform: 'translateY(0px)'},
            { offset: 1, transform: 'translateY(20px)', scale:'70%'},
          ])
          .fromTo('--border-radius', '7px', '0%')
          .onFinish(()=>{
            tripAnimationDone = true;
          })

          if(route.name != 'Dashboard') animate('init')
    })

    router.beforeEach((to, from, next)=>{
        mobileTabs.value = to.meta.mobiletabs
        animate(to.name)
        next()
    });

    async function animate(toName:any){
      if(toName == 'Dashboard') {
        await tripAnimation.direction('reverse')
        await tripAnimation.play()
        tripAnimationDone = false;
      }
      else if (mobileTabs.value && ! tripAnimationDone){
        await tripAnimation.direction('normal')
        await tripAnimation.play()
      }
    }


</script>
  
<style scoped>
    ion-tab-bar {
      padding-top: 5px;
      padding-bottom: 5px;
      --background: var(--ion-color-petrol);
      --color: var(--ion-color-step-600);
      --color-selected: var(--ion-color-light);
    }

    ion-tab-button .tab-icon {
      font-size: 2rem !important;
    }

    ion-fab-button {
      width: 80px;
      height: 100px;
      position: relative;
      top: 15px;
      --border-radius: 10px;
    }

    .trip-icon{
      --background: none;
    }
    .trip-icon ion-text {
      font-size: 1rem;
    }
    .trip-icon i{
      font-size: 2.9rem !important;
    }
</style>
  