<template>
        <ion-card size="4" class="oauth" button @click="googleOAuth2">
            <ion-card-header>
                <ion-item color="none" lines="none">
                    <ion-icon src="/google.svg" size="large"></ion-icon>
                    <ion-label class="ion-margin-start">Google</ion-label>
                </ion-item>               
            </ion-card-header>
        </ion-card>

        <ion-card size="4" class="oauth" button @click="facebookOAuth2">
            <ion-card-header>
                <ion-item color="none" lines="none">
                    <ion-icon src="/facebook.svg" size="large" class="facebook"></ion-icon>
                    <ion-label class="ion-margin-start">Facebook</ion-label>
                </ion-item>               
            </ion-card-header>
        </ion-card>

        <ion-card size="4" class="oauth" button @click="appleOAuth2">
            <ion-card-header>
                <ion-item color="none" lines="none">
                    <ion-icon src="/apple.svg" size="large"></ion-icon>
                    <ion-label class="ion-margin-start">Apple ID</ion-label>
                </ion-item>               
            </ion-card-header>
        </ion-card>

</template>

<script setup lang="ts">
    import { logoApple, logoFacebook, logoGoogle } from 'ionicons/icons';
    import { authAPI } from '@/libs/backend'

    function googleOAuth2(){
        authAPI.GoogleOAuth2().then((response:any)=>{
            genericOAuth2(response)
        });
    }

    function facebookOAuth2(){
        authAPI.FacebookOAuth2().then((response:any)=>{
            genericOAuth2(response)
        });
    }

    function appleOAuth2(){
        authAPI.AppleOAuth2().then((response:any)=>{
            genericOAuth2(response)
        });
    }

    function genericOAuth2(response:any){
        location = response.authorization_url
    }
</script>

<style scoped>
    .oauth {
        margin-inline: 0px;
        border: unset;
        border-color: unset;
    }
    .oauth ion-card-header{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .facebook {
        color: #1773ea
    }
    .google {

    }
</style>