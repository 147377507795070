<template>
    <ion-modal ref="modal" mode="ios">
        <ion-header>
            <ion-item lines="none" color="none">
                <ion-buttons slot="end">
                    <ion-button @click="dismiss()">
                        <ion-icon slot="icon-only" color="light" :icon="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item lines="none">
        </ion-header>
        <ion-content-auth-guard :guardNavCallback="dismiss">
            <div v-if="authStore.isLoggedIn">
                <ion-text color="primary" class="ion-text-center"><h2>{{ $t('Upload to my Tripy') }}</h2></ion-text>
                <ion-list v-if="devicesStore.verifiedDevices.length">
                    <ion-item v-for="device of devicesStore.verifiedDevices" button mode="md" @click="selectDevice(device)">
                        <ion-icon slot="start" :icon="device.selected ? checkmarkCircle:squareOutline" :color="device.selected ? 'success':''"></ion-icon>
                        <ion-label v-if="device.label">{{ device.label }}</ion-label>
                        <ion-label v-else>
                            <p>#{{device.serial_number}}</p>
                            <p><InlineDocumentFieldEdit :documentAPI="deviceAPI" :document="device" :documentId="device.serial_number" fieldName="label" placeholder="Set a label">{{device.text}}</InlineDocumentFieldEdit></p>
                        </ion-label>
                        
                    </ion-item>
                </ion-list>
                <div v-else class="ion-text-center ion-padding">
                    <ion-button expand="block" shape="rounded" fill="outline" class="ion-margin" @click="openDevices">
                        <ion-icon slot="start" :icon="addOutline"></ion-icon>
                        {{ $t('Register your Tripy GPS') }}
                    </ion-button>
                    <ion-button expand="block" shape="rounded" fill="outline" class="ion-margin">
                        <ion-icon slot="start" :icon="cartOutline"></ion-icon>
                        {{ $t('Buy a Tripy GPS') }}
                    </ion-button>
                    <ion-item  button>
                        <ion-label>
                            {{ $t('What is a Tripy GPS ?!') }}
                        </ion-label>
                    </ion-item>
                </div>
            </div>
            <div v-else class="ion-margin-top">
                <ion-row>
                    <ion-col size="12">
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                    <h3 class="ion-text-center">{{$t('You are not logged in')}}</h3>
                    <ion-button @click="exportGPX" expand="block" shape="rounded">
                        {{ $t('Login') }}
                    </ion-button>
                    <ion-button @click="exportGPX" expand="block" shape="rounded" fill="clear">
                        {{ $t('Account creation') }}
                    </ion-button>
                    </ion-col>
                </ion-row>
            </div>
        </ion-content-auth-guard>
        <ion-footer>
            <ion-toolbar>
                <ion-row>
                    <ion-col v-if="saveGPX" :size="devicesStore.verifiedDevices.length ? 6:12">
                        <ion-button @click="exportGPX" expand="block" shape="rounded"  fill="outline" color="light">
                            {{ $t('Export GPX') }}
                        </ion-button>
                    </ion-col>
                    <ion-col v-else :size="6">
                    </ion-col>
                    <ion-col v-if="authStore.isLoggedIn && devicesStore.verifiedDevices.length" size="6">
                        <ion-button @click="upload" expand="block" shape="rounded" fill="outline" :disabled="!selectedDevices.length || uploading">
                            <ion-spinner v-if="uploading" name="crescent"></ion-spinner>
                            <ion-icon v-else slot="start" :icon="cloudUpload"></ion-icon>
                            {{ $t('Upload') }}
                        </ion-button>
                    </ion-col>
                </ion-row>

             </ion-toolbar>
        </ion-footer>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref, onMounted, computed } from 'vue';
    import { checkmarkCircle, cartOutline,  addOutline, cloudUpload, squareOutline, close } from 'ionicons/icons';
    import { deviceAPI } from '@/libs/backend';
    import { useRouter } from 'vue-router';
    import IonContentAuthGuard from '@/components/IonContentAuthGuard.vue';
    import InlineDocumentFieldEdit from './InlineDocumentFieldEdit.vue';
    import { DevicesStore } from '@/stores/DevicesStore';
    import { AuthStore } from '@/stores/AuthStore';
    

    // variables refs
    const selectedDevices:any = computed(()=> devicesStore.verifiedDevices.filter((d:any) => d.selected == true))
    const uploading = ref(false);
    var roadbookUploadID:string = ''

    // HTML components refs
    const modal = ref();

    // Vue
    const router = useRouter();

    // Stores
    const devicesStore = DevicesStore()
    const authStore = AuthStore();

    defineProps({
        saveGPX: {
            type: Boolean,
            default: true
        }
    });

    defineExpose({
        present,
        close: dismiss,
    });

    const emit = defineEmits<{
        (e: 'uploaded'): void
    }>();

    onMounted(async()=>{
        if(authStore.isLoggedIn){
            devicesStore.init()
    
            // select by default first one if only one device
            if(devicesStore.verifiedDevices.length == 1) {
                devicesStore.verifiedDevices[0].selected = true
            }
        }
    });

    function present(roadbookID:string){
        modal.value.$el.present()
        roadbookUploadID = roadbookID;
    }

    function dismiss(){
        modal.value.$el.dismiss()
    }

    function openDevices(){
        router.push('/devices')
        dismiss()
    }

    function selectDevice(device:any){
        if(device.selected == undefined) device.selected = true;
        else device.selected = ! device.selected;
    }

    function exportGPX(){
        alert('TODO !!')
    }

    async function upload(){
        uploading.value = true;
        for(let device of selectedDevices.value){
            await deviceAPI.uploadRoadbook(device.serial_number, roadbookUploadID)
        }
        await devicesStore.refresh()
        uploading.value = false;
        dismiss();
        emit('uploaded')
    }
</script>

<style scoped>
    ion-modal {
        --height: 55%;
        --border-radius: 16px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .modal-section {
        font-size: 20px;
    }

    .search-card {
        margin: 0 0 20px 0;
    }

    .toolbar-title {
        text-overflow: unset !important;
    }

    ion-chip.chip-outline {
        background-color: var(--ion-color-success) !important;
        color: white
    }

    ion-content ion-card {
        box-shadow: none;
    }

    ion-segment { 
        width: 100%;
    }
</style>