<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <BackNavButton></BackNavButton>
                <ion-title class="ion-text-uppercase">{{$t('Flash')}}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <PrepareFlash></PrepareFlash>
        </ion-content>

        <ion-footer>
            <ion-toolbar>
                <ion-button @click="route" expand="block" class="ion-margin" :disabled="!roadbookStore.isRoutable()">
                    {{$t('Create a trip')}}
                    <ion-icon slot="start" :icon="caretForward"></ion-icon>
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ion-page>

</template>

<script setup lang="ts">
    import BackNavButton from '@/components/BackNavButton.vue';
    import { inject, onMounted } from 'vue';
    import { caretForward } from 'ionicons/icons';
    import PrepareFlash from '@/components/PrepareFlash.vue';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { useRouter } from 'vue-router';

    // variables refs
    const isDesktop = inject('isDesktop')
    // Stores
    const roadbookStore = roadbookEditStore()
    // Vue
    const router = useRouter()

    onMounted(()=>{
        // THIS PAGE IS MOBILE ONLY
        if(isDesktop) router.replace('/roadbooks/new/edit/flash')
    });

    function route(){
        router.push('/roadbooks/new/edit/flash')
    };

</script>

<style scoped>
    ion-footer {
        border-top: 2px var(--ion-color-petrol) solid !important;
    }
</style>