import { defineStore } from 'pinia'
import { locationAPI } from '@/libs/backend'
import { isLoggedIn } from '@/stores/AuthStore';
import { useStorage } from '@vueuse/core'

/* Sorage for Roabook edition on map pages */
export const searchPlaceStore = defineStore("searchplace", {
    state: () => ({
      history: <Array<any>>[],
    }),
    actions: {
      async fetch() {
        this.history = useStorage('searchHistory', []) // Store into local storage by default
        if(isLoggedIn()){
          this.history = await locationAPI.searchHistory();
          return this.history;
        }
      },

      init(){
        this.$reset();
        this.fetch();
      },

      async addSearchHistory(coordinates:number[], address:string, label:string){
        let historyEntry = {coordinates:coordinates, address:address, label:label}
        if(isLoggedIn()){
          this.history = await locationAPI.addSearchHistory(historyEntry)
        }
        else {
          let historyLimit = 3;

          let pop_index = this.history.findIndex((el)=>el.address == address);

          if(pop_index != -1){ // remove from history if already present
            this.history.splice(pop_index, 1)
          }
          else if(this.history.length >= historyLimit){ // remove last one
            this.history.pop()
          }
      
          this.history.splice(0, 0, historyEntry)
        }
      }
    },
})