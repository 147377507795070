<template>
    <ion-content v-if="authStore.isLoggedIn">
        <slot></slot>
    </ion-content>
    <ion-content v-else>
        <div class="div-center ion-text-center">
            <ion-row>
                <ion-col push="1" size="10">
                    <h1>{{$t('Login for more features')}}</h1>
                </ion-col>
                <ion-col push="1" size="10">
                    <ion-button @click="goLogin" expand="block">
                        {{$t('Login')}}
                    </ion-button>
                </ion-col>
            </ion-row>
        </div>
    </ion-content>
</template>

<script setup lang="ts">
    import { useRouter } from 'vue-router';
    import { AuthStore } from '@/stores/AuthStore';
    import { ref } from 'vue';

    const router = useRouter()
    const authStore = AuthStore();

    const props = defineProps({
        guardNavCallback:{
            type: Function,
            default: undefined
        },
    })

    function goLogin(){
        navCallback()
        router.push('/login')
    }
    
    function navCallback(){
        if(props.guardNavCallback) props.guardNavCallback()
    }
</script>

<style scoped>
    .div-center {
        width: 100% !important;
    }

</style>