import { defineStore } from 'pinia'
import { roadbookAPI, graphhopperAPI } from '@/libs/backend'
import { presentToast } from '@/libs/userExperience'
import { i18n } from '@/main';

/* Sorage for Roabook edition on map pages */
export const RoadbooksStore = defineStore("roadbooks", {
    state: () => ({
        roadbooks: <Array<any>>[],

        all: <Array<any>>[], // without archived
        lastActivities: <Array<any>>[],
        favorites: <Array<any>>[],
        flash: <Array<any>>[],
        discovery: <Array<any>>[],
        routing: <Array<any>>[],
        archived: <Array<any>>[],
        categories: <object>{},

        initialized: false,
        searchResult: <Array<any>>[],
    }),
    actions: {
        /* 
          [ROADBOOK DATA ACTIONS]
        */
        async fetch() {
            try {
                roadbookAPI.disableNextErrorToast()
                this.lastActivities = await roadbookAPI.all({limit:6, sorting:['-updated_date']});
                roadbookAPI.disableNextErrorToast()
                this.roadbooks = await roadbookAPI.allCoords();
                this.sort();
            }
            catch (error) {
                presentToast(i18n.global.t('Roadbooks fetch error'), 'danger');
            }
        },
        async init() {
            this.$reset();
            await this.fetch()
            this.initialized = true;
        },

        sort(){
            this.all = []
            this.favorites = []
            this.flash = []
            this.discovery = []
            this.routing = []
            this.archived = []
            for(let roadbook of this.roadbooks){
                if(roadbook.archived == true) this.archived.push(roadbook)
                else{
                    this.all.push(roadbook)
                    if(roadbook.favorite == true) this.favorites.push(roadbook)
                    if(roadbook.type == 'flash') this.flash.push(roadbook)
                    if(roadbook.type == 'discovery') this.discovery.push(roadbook)
                    if(roadbook.type == 'routing') this.routing.push(roadbook)
                }    
                // TODO : this.<category_name>.push(roadbook) (créer si inexistant)
            }
        },

        /* 
          [UTILS ACTIONS]
        */
        get(category:string){
            if(category == 'all') return this.all;
            else if(category == 'lastActivities') return this.lastActivities;
            else if(category == 'favorites') return this.favorites;
            else if(category == 'flash') return this.flash;
            else if(category == 'discovery') return this.discovery;
            else if(category == 'routing') return this.routing;
            else if(category == 'archived') return this.archived;
        },

        empty(){
            return this.roadbooks.length == 0;
        },

        populated(){
            return this.roadbooks.length > 0;
        }
    },
})