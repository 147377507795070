// Apple Map Kit : https://satellites.pro/plan/France_map#A43.102988,6.819763,8

// TODO Pour passer en vectoriel:

// Sources vectorielles gratuites : https://www.arcgis.com/apps/View/index.html?appid=df7cee38677f479c8697026ebf920431

export const tileProviders:any = {
    'openstreetmap':{
      id: 'openstreetmap',
      name: 'Classique',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      options: {
        subdomains: 'abcd',
        maxZoom: 19,
      }
    },
    'arcgis-worldtopomap': {
      id: 'arcgis-worldtopomap',
      name: 'Reliefs',
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      options: {
        maxZoom: 24,
      }
    },
    'opentopomap': {
      id: 'opentopomap',
      name: 'Topographique',
      url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
      options: {
        subdomains: 'abcd',
        maxZoom: 17,
      }
    },
    'arcgis-worldimagery': {
      id: 'arcgis-worldimagery',
      name: 'Terrain',
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.jpg',
      options: {
        maxZoom: 24
      }
    },
   
    // {
    //   name: 'TomTomRasterMap',
    //   url: 'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=VIPxjGwfgz2f7b5QLmBHgM0OVdwEb0uo&tileSize=512',
    //   options: {
    //     maxZoom: 23,
    //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">TomTom Map</a>'
    //   }
    // },
    // {
    //   name: 'TomTomRasterMap Proxy',
    //   url: 'https://rallye2.tripy.be/tiles/tomtom/{z}/{x}/{y}.png',
    //   options: {
    //     maxZoom: 23,
    //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">TomTom Map</a>'
    //   }
    // },

    // NOTE : Les cartes STAMEN sont trop lentes
    // {
    //   name: 'Stamen Design Toner',
    //   url: 'https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png',
    //   options: {
    //     attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     subdomains: 'abcd',
    //     minZoom: 0,
    //     maxZoom: 20
    //   }
    // },
    // {
    //   name: 'Noir et blanc',
    //   url: 'https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png',
    //   options: {
    //     attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     subdomains: 'abcd',
    //     minZoom: 0,
    //     maxZoom: 20
    //   }
    // },
    // {
    //   name: 'Noir et blanc 2',
    //   url: 'https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png',
    //   options: {
    //     attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     subdomains: 'abcd',
    //     minZoom: 0,
    //     maxZoom: 20
    //   }
    // }
    // NOTE : Les cartes STAMEN sont trop lentes,
    // {
    //   name: 'Alidade dark',
    //   url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    //   options: {
    //     attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     subdomains: 'abcd',
    //     minZoom: 0,
    //     maxZoom: 20
    //   }
    // },
    // {
    //   name: 'Alidade salellite',
    //   url: 'https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg',
    //   options: {
    //     attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     subdomains: 'abcd',
    //     minZoom: 0,
    //     maxZoom: 20
    //   }
    // },
  }

  export const defaultMapLayerId = 'openstreetmap'
  export const defaultMapLayer = tileProviders[defaultMapLayerId].url;