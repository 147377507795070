<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">
                <PageLoader :loading="true" :spinner="true"></PageLoader>
            </ion-row>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { onMounted } from 'vue';
    import { authAPI } from '@/libs/backend'
    import { useRoute } from 'vue-router';
    import PageLoader from '@/components/PageLoader.vue';
    import { AuthStore } from '@/stores/AuthStore';
    
    const route = useRoute();
    const authStore = AuthStore();
    
    onMounted(()=>{
        OAuthLogin();
    })
    
    function OAuthLogin(){
        let params = {}
        let provider:string|undefined = route.query.provider?.toString()
        let code:string|undefined = route.query.code?.toString()
        let state:string|undefined = route.query.state?.toString()
        let error:string|undefined = route.query.error?.toString()

        if(code){
            params = {
                code:code,
                state:state,
            }
        }
        else if(error){
            params = {
                state:state,
                error:error,
            }
        }
        else{
            close()
        }
        
        if(provider=='facebook')    FacebookLogin(params)
        else if(provider=='google') GoogleLogin(params)
        else if(provider=='apple')  AppleLogin(params)
        else close()
    }

    function FacebookLogin(params:any){
        authAPI.FacebookOAuth2Login(params).then((response)=>{
            close(response)
        },
        (error)=>{
            close()
        })
    }

    function GoogleLogin(params:any){
        // Add Google extra params
        let scope:string|undefined = route.query.scope?.toString()
        let authuser:string|undefined = route.query.authuser?.toString()
        let prompt:string|undefined = route.query.prompt?.toString()
        let googleExtraParams:any = {
            scope: scope,
            authuser: authuser,
            prompt: prompt
        }
        params = { ...params, ...googleExtraParams }

        authAPI.GoogleOAuth2Login(params).then((response)=>{
            console.log(response)
            close(response)
        },
        (error)=>{
            close()
        })
    }

    function AppleLogin(params:any){
        authAPI.AppleOAuth2Login(params).then((response)=>{
                close(response)
            },
            (error)=>{
                close()
            })
    }

    function close(response:any=null){
        authStore.postLoginAction(response?.access_token)
        // window.opener.postMessage(response);
        // window.close()
    }
</script>

<style scoped>
    @import '@/theme/login.css';

    ion-spinner {
        transform: scale(8);
        z-index: 9999;
        left: 50%;
        top: 50%;
        margin-top: -15px;   /* = -height / 2   */
        margin-left: -15px;  /* = -width / 2    */
        position: fixed;     /* Fixed is better */
    }
</style>