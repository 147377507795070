<template>
    <div>
        
        <!-- overlay during map loading -->
        <!-- <PageLoader :loading="!isReady()" ></PageLoader> -->

        <ion-row>
            <ion-col size="12" class="ion-text-center">
                {{ $t('Pick a location') }}
            </ion-col>
        </ion-row>
        <div :style="'height:'+height+'px; width:100%'">
        
            <l-map ref="map" :zoom="mapZoom" :center="mapCenter" :useGlobalLeaflet="parameters.useGlobalLeaflet" :options="parameters.options"
                @ready="initMap"
                @click="selectLocation"
            >
        
                <l-tile-layer :url="defaultMapLayer" layer-type="base" name="TripyMap3"></l-tile-layer>
                
                <l-marker v-if="coordinates" :lat-lng="coordinates" @contextmenu="$event.target.openPopup()">
                    <l-icon :iconSize="[46, 60]" :iconAnchor="[31, 44]" :popupAnchor="[0,-40]" :options="{className:''}">
                        <img src="/start.svg" style="height:46px;width:60px;"/>
                    </l-icon>
                    <PopoverLocationPicker ref="popover" :latLon="coordinates" :location="pickedMapPlace.display_name" @select="select()"></PopoverLocationPicker>
                </l-marker>


                <l-circle-marker v-if="geoLocation.location" @click="coordinates = undefined" :lat-lng="geoLocation.location" :weight="6" :fillOpacity="1" :radius="10" color="#222428" fill-color="#ffd50f">
                    <PopoverLocationPicker ref="currentPopover" :latLon="coordinates" :location="pickedMapPlace.display_name" @select="select()"></PopoverLocationPicker>
                </l-circle-marker>
            </l-map>
        </div>

    </div>
</template>

<script setup lang="ts">
   
    /* Leaflet */
    import "leaflet"; // to use L object
    import "leaflet/dist/leaflet.css";
    import { LMap, LTileLayer, LMarker, LIcon, LCircleMarker } from "@vue-leaflet/vue-leaflet";
    import { computed, ref, watch } from 'vue';
    import { waitElVisible } from '@/libs/userExperience';
    import PopoverLocationPicker from "./MapPopover/PopoverLocationPicker.vue";
    import { geoLocationStore } from "@/stores/geoLocationStore";
    import { defaultMapLayer } from "@/libs/tileProviders";
    import { locationAPI } from "@/libs/backend";

    // HTML components refs
    const map = ref();
    const popover = ref()

    // Variable refs
    const pickedMapPlace:any = ref({})
    const coordinates = ref(undefined)
    const geoLocation = geoLocationStore();
    const zoom:any = ref(null);
    const center:any = ref(null);
    const mapZoom = computed({
        get() {
            if(zoom.value) return zoom.value
            else return props.parameters.zoom
        },
        set(value) { zoom.value = value }
    })
    const mapCenter = computed({
        get() {
            if(center.value) return center.value
            else return props.parameters.center
        },
        set(value) { center.value = value }
    })

    const props = defineProps({
        parameters: {
            type: Object,
            default: {
                // TODO: local position if detected, Home country if not, else Europe center
                center: [46.66181748307193, 7.799474973236367], // centered on Europe by default
                zoom: 3,
                useGlobalLeaflet: false,
                options: {
                    zoomControl: false, 
                    zoomSnap:0.1, // Zoom precision ratio, default 1. Usefull to use fitBounds with padding precision
                    attributionControl: false // disable leaflet map attribution label
                },
            }
        },
        height: {
            type: Number,
            default: window.innerHeight - 75 // 75px is the default header height in style.css 
        },
        showLoader: {
            type: Boolean,
            required: false,
            default: true
        },
    });

    defineExpose({
         showCurrentLocation,
    });

    const emit = defineEmits([
        'selected'
    ]); 

    
    async function selectLocation(event:any){
        coordinates.value = event.latlng
        pickedMapPlace.value = ''
        pickedMapPlace.value = await locationAPI.reverse(event.latlng.lat, event.latlng.lng);
        waitElVisible(()=>{
            popover.value.toggle();
        },200)
    }

    function initMap(){
        // TODOOOOO : voir si c'est le bug de fenêtrage de la carte https://leafletjs.com/reference.html
        // map.value.leafletObject.invalidateSize(true); 

        if(geoLocation.location){
            focusCurrentLocation()
        }
        else {
            watch(() => geoLocation.location, () => {
                focusCurrentLocation()
            }, { once: true })

        }
    }  
    
    function focusCurrentLocation(){
        map.value.leafletObject.setView(geoLocation.location, 10)
    }


    function closePopups(){
        if(map.value.leafletObject)
            map.value.leafletObject.closePopup();
    }

    function focusLatLon(latlng:any){
        closePopups();
        map.value.leafletObject.setView(latlng, 14)
    }

    function showCurrentLocation(){
        if(geoLocation.location) focusLatLon(geoLocation.location)
    }

    function select(){
        emit('selected', {
            coordinates: coordinates.value,
            address: pickedMapPlace.value.display_name,
            label:  pickedMapPlace.value.name
        }); 
        popover.value.toggle()
    }
</script>

<style scoped>
.leaflet-container{
    /* cursor: pointer !important; */
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    display: none !important;
    visibility: hidden !important;
}
		
</style>