
<template>
    <l-popup ref="popover" 
        :options="{
            className:'wp-popover dark-popup', 
            closeButton:false, 
            minWidth:240, 
            maxWidth:240
        }"
    >
        <ion-item color="none" lines="none">
            <ion-text color="light" style="font-size:0.8rem">
                {{ location }}
            </ion-text>
        </ion-item>
        <ion-row>
            <ion-col size="6">
                <ion-button @click="dismiss" expand="block" fill="clear" color="medium" size="small">
                    {{$t('Cancel')}}
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button @click="emit('select');" expand="block" fill="clear" size="small">
                    {{$t('Select')}}
                </ion-button>
            </ion-col>
        </ion-row>
    </l-popup>

</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { close } from 'ionicons/icons';
    import { LPopup } from "@vue-leaflet/vue-leaflet";

    // HTML components refs
    const popover = ref();

    const props = defineProps({
        location: {
            type: String,
            required: true
        }
    })

    const emit = defineEmits([
        'dismiss',
        'select'
    ]);

    defineExpose({
        dismiss,
        toggle
    });

    function dismiss(event:(Event|null)=null){
        if(event){
            event.stopPropagation()
        }
        popover.value.leafletObject.close();
        emit('dismiss')
    }

    function toggle(){
        popover.value.leafletObject.toggle()
    }
</script>

<style scoped>
    ion-text{
        font-size: 1rem;
    }
</style>