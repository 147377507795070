<template>
    <div ref="compass" class="compass" 
        @mousedown="hold = true" @mousemove="cursorMoveUpdate" @mouseup="hold = false;"
        @touchstart="hold = true;" @touchmove="cursorMoveUpdate" @touchend="hold = false;"
        @click="updateHeading"
    >
        <div class="dial">
            <div class="dot" v-bind:style="{transform: `rotate(-75deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(-60deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(-45deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(-30deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(-15deg) translateX(${dotWidth}px)`}"></div>

            <div class="dot" v-bind:style="{transform: `rotate(15deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(30deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(45deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(60deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(75deg) translateX(${dotWidth}px)`}"></div>

            <div class="dot" v-bind:style="{transform: `rotate(105deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(120deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(135deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(150deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(165deg) translateX(${dotWidth}px)`}"></div>

            <div class="dot" v-bind:style="{transform: `rotate(195deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(210deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(225deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(240deg) translateX(${dotWidth}px)`}"></div>
            <div class="dot" v-bind:style="{transform: `rotate(255deg) translateX(${dotWidth}px)`}"></div>
        </div>
        <div class="letter north">N</div>
        <div class="north"></div>
        <div class="letter south">S</div>
        <div class="south"></div>
        <div class="letter east">E</div>
        <div class="east"></div>
        <div class="letter west">W</div>
        <div class="west"></div>

        <div class="compass-center">
            <div class="compass-pointer" v-bind:style="{ transform: `rotate(${heading}deg)`, transition: hold?'':'transform .3s ease-in-out' }">
            </div>
            <div class="compass-values">
                <ion-text class="heading-numeric" color="anthracite">{{ heading }}°</ion-text>
            </div>
        </div>
        <resize-observer @notify="updateWidth" />
    </div>

</template>

<script setup type="ts">
    import { dice } from 'ionicons/icons';
    import { computed, inject, onMounted, onUpdated, ref, watch } from 'vue';

    const compass = ref()
    const hold = ref(false)
    const heading = ref(0)
    const headingTextual = ref('N')

    const isDesktop = inject('isDesktop')
    const isMobile = inject('isMobile')

    const dotWidth = ref(0)

    defineExpose({
        randomHeading,
    });

    const emit = defineEmits(['heading'])

    function randomHeading() {
        heading.value = Math.floor(Math.random() * (360 - 0 + 1)) + 0;
    }

    function updateWidth(event){
        dotWidth.value = event.width * 0.4
    }

    function cursorMoveUpdate(event){
        if(!hold.value) return
        updateHeading(event)
    }

    function updateHeading(event) {
        const compassEl = document.querySelector('.compass');
        const rect = compassEl.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;

        let x = event.clientX - centerX;
        let y = event.clientY - centerY;
        if(event.touches){ // mobile touch events
            x = event.touches[0].clientX - centerX;
            y = event.touches[0].clientY - centerY;
        }
        
        let angle = Math.atan2(y, x) * 180 / Math.PI + 90;
        if (angle < 0) angle += 360;

        // round to 10 by 10 step
        angle = Math.round(parseInt(angle) / 10) *10
        
        heading.value = angle

        emit('heading', heading.value)
    }
</script>

<style scoped>
.compass {
        background: var(--ion-color-anthracite);
        border-radius: 100%;
        border: 10px solid var(--ion-color-primary);
        box-shadow: inset 0 0 15px rgba(0,0,0,0.5);
        position: relative;

        min-width: 250px;
        max-width: 400px;
        width: 100%;
        aspect-ratio: 1 / 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        cursor: grab;
        /* disable text selection */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        margin-left: auto;
        margin-right: auto;
    }

    .compass-center {
        position: relative;
        top: 25%;
        right: 25%;
        text-align: center;
        z-index: 9999;
    }

    .compass-pointer {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        aspect-ratio: 1 / 1;
        background-color: var(--ion-color-primary);
        border-radius: 100%;
        box-shadow: 0 0 0 32px rgba(0, 0, 0, 0.2);
    }

    .compass-pointer:after {
        content: '';
        position: relative;
        border-style: solid;
        border-width: 0 40px 48px 40px;
        border-color: transparent transparent var(--ion-color-primary-shade) transparent;
        top: -55px;
    }


    .compass-values {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        box-shadow: inset 0 0 0 15px var(--ion-color-primary-shade);
        padding-top: 16%;
    }

    .heading-textual {
        color: var(--ion-color-step-300);
        font-size: 2.5rem;;
        line-height: 1rem;
    }

    .heading-numeric {
        font-size: 3rem;;
    }

    .compass .letter {
        text-align: right;
        position: absolute;
        color: var(--ion-color-light);
        font-size: 3em;
    }
    .compass .letter.north {
        font-weight: bold;
        top: 0;
        left: 50%;
        margin-left: -0.4em;
    }

    .compass .letter.south {
        bottom: 0;
        left: 50%;
        margin-left: -0.4em;
    }

    .compass .letter.west {
        top: 50%;
        left: 2%;
        margin-top: -0.6em;
    }

    .compass .letter.east {
        top: 50%;
        right: 2%;
        margin-top: -.4em;
    }

    .compass .dial .dot{
        position: absolute;
        left: 48%;
        top: 50%;
        display: block;
        width: 1em;
        height: .2em;
        border-radius: 50%;
        background: rgba(var(--ion-color-light-rgb), 0.3);
    }

    .random-button {
        --background:  var(--ion-color-anthracite);
        --background-hover:  var(--ion-color-anthracite);
        --background-activated:  var(--ion-color-anthracite);
        --background-focused:  var(--ion-color-anthracite);

        --color: var(--ion-color-light);

        --border-radius: 100%;
        font-size: 2rem;
    }
</style>