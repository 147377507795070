import { defineStore } from 'pinia';
import router from '@/router';
import { authAPI, userAPI } from '@/libs/backend'
import { useStorage } from '@vueuse/core'

export function isLoggedIn(){
    return (localStorage.getItem('auth_token'));
}

export const AuthStore = defineStore({
    id: 'auth',
    state: () => ({
        userData: <any> useStorage('userdata', {}),
        token: <any> useStorage('auth_token', '')
    }),
    getters: {
        isAuthentified(state){
            return (state.token)
        },
        isLoggedIn(state){
            return (state.token)
        },
    },
    actions: {
         clear() {
            this.token = '';
            this.userData = {};
        },

        async login(username:string, password:string) {
            return authAPI.login(username, password).then(
                (data:any)=>{
                    if(data && data.success == false){
                        return data // login error reason
                    }
                    else if(data && data.access_token){
                        this.postLoginAction(data.access_token)
                        return data
                    }
                    else{
                        // TODO : user inteface actions ??
                        console.error("Invalid token")
                    }
                },
                (error:any)=>{
                    // TODO : >= 400 Error seems to not go here :/
                }
            )
        },
        
        postLoginAction(accessToken:string){
            if(accessToken){
                this.token = accessToken;
                userAPI.userInfos().then((userInfos)=>{
                    this.userData = userInfos
                    router.push('/');
                })
            }
        },

        fetchUserData(){
            userAPI.userInfos().then((userInfos)=>{
                this.userData = userInfos
            })
        },
        
        async logout() {
            const authStore = AuthStore();
            authAPI.logout().then(
                ()=>{
                    authStore.clear();
                    router.push('/login');
                },
                (error:any)=>{
                    // TODO : >= 400 Error seems to not go here :/
                }
            )
        }
    }
});