import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '@/views/auth/Login.vue';
import Signup from '@/views/auth/Signup.vue';
import Dashboard from '@/views/Dashboard.vue';
import Roadbooks from '@/views/Roadbooks.vue';
import RoadbookEditExpert from '@/views/RoadbookEditExpert.vue';
import Account from '@/views/Account.vue';
import Parameters from '@/views/Parameters.vue';
import Devices from '@/views/Devices.vue';
import NotFound from '@/views/NotFound.vue';
import Community from '@/views/Community.vue';
import RecoverPassword from '@/views/auth/RecoverPassword.vue';
import Confidentality from '@/views/Confidentality.vue';
import ConditionsOfUse from '@/views/ConditionsOfUse.vue';
import ChangePassword from '@/views/auth/ChangePassword.vue';
import VerifyAccount from '@/views/auth/VerifyAccount.vue';
import OAuth2Redirect from '@/views/auth/OAuth2Redirect.vue';
import UsernameCreation from '@/views/auth/UsernameCreation.vue';
import { AuthStore } from '@/stores/AuthStore';
import TestGeocoder from '@/views/TestGeocoder.vue';
import Profile from '@/views/Profile.vue';
import HelpCenter from '@/views/HelpCenter.vue';
import RoadbookPrepareFlash from '@/views/RoadbookPrepareFlash.vue';
import RoadbookPrepareDiscovery from '@/views/RoadbookPrepareDiscovery.vue';
import RoadbookEditSimple from '@/views/RoadbookEditSimple.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { mobiletabs: false }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: { mobiletabs: false }
  },
  {
    path: '/resetpassword',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: { mobiletabs: false }
  },
  {
    path: '/changepassword/:email/token/:token/', // Important : final '/' is mandatory to router for long token
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { mobiletabs: false },
  },
  {
    path: '/verify/:email/token/:token/', // Important : final '/' is mandatory to router for long token
    name: 'VerifyAccount',
    component: VerifyAccount,
    meta: { mobiletabs: false },
  },
  {
    path: '/oauth2redirect',
    name: 'OAuth2Redirect',
    component: OAuth2Redirect,
    meta: { mobiletabs: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { mobiletabs: true }
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    meta: { mobiletabs: true }
  },
  {
    path: '/roadbooks',
    name: 'Roadbooks',
    component: Roadbooks,
    meta: { mobiletabs: true }
  },
  {
    path: '/roadbooks/prepare/flash',
    name: 'RoadbookPrepareFlash',
    component: RoadbookPrepareFlash,
    meta: { mobiletabs: false }
  },
  {
    path: '/roadbooks/prepare/discovery',
    name: 'RoadbookPrepareDiscovery',
    component: RoadbookPrepareDiscovery,
    meta: { mobiletabs: false }
  },
  {
    path: '/roadbooks/:id/edit/:type',
    name: 'RoadbookEditSimple',
    component: RoadbookEditSimple,
    meta: { mobiletabs: false }
  },

  {
    path: '/roadbooks/prepare/routing',
    redirect: '/roadbooks/new/edit/routing',
  },
  {
    path: '/roadbooks/:id/edit/routing',
    name: 'RoadbookEditExpert',
    component: RoadbookEditExpert,
    meta: { mobiletabs: false }
  },
  {
    path: '/conditions-of-use',
    name: 'ConditionsOfUse',
    component: ConditionsOfUse,
    meta: { mobiletabs: true}
  },
  {
    path: '/confidentality',
    name: 'Confidentality',
    component: Confidentality,
    meta: { mobiletabs: true}
  },
  {
    path: '/user/defineusername',
    name: 'UsernameCreation',
    component: UsernameCreation,
    meta: { mobiletabs: false, authGuard:true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { mobiletabs: true }
  },
  {
    path: '/profile/account',
    name: 'Account',
    component: Account,
    meta: { mobiletabs: false, authGuard:true }
  },
  {
    path: '/profile/parameters',
    name: 'Parameters',
    component: Parameters,
    meta: { mobiletabs: false, authGuard:true }
  },
  {
    path: '/profile/devices',
    name: 'Devices',
    component: Devices,
    meta: { mobiletabs: false, authGuard:true }
  },
  {
    path: '/roadbook/oops',
    name: 'NotFound',
    component: NotFound,
    meta: { mobiletabs: true}
  },
  {
    path: '/help',
    name: 'Help',
    component: HelpCenter,
    meta: { mobiletabs: true }
  },
  // redirect any other location to dashboard
  { 
    path: '/:pathMatch(.*)*', 
    redirect: '/dashboard' 
  },
  {
    path: '/test/geocoder',
    name: 'TestGecocoder',
    component: TestGeocoder,
    // meta: { mobiletabs: true }
  },
]




const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})


/*
  Router Guard : redirect user to define an account 'username' 
                 This use case is procuced after OAuth2 (Google/Facebook/AppleID) account creation
                 https://router.vuejs.org/guide/advanced/navigation-guards.html
*/
const usernameCreationGuard = (to, from, next)=>{
  const authStore = AuthStore();
  let user:any = authStore.userData
  if(authStore.isAuthentified && user && ! user.username && to.name != 'UsernameCreation'){
    return next({name: 'UsernameCreation'})
  }
  else {
    next()
  }
}

/*
Router Guard : redirect an user without authentication to Dashboard page
IMPORTANT : this is mainly for sub-pages. Use 
https://router.vuejs.org/guide/advanced/navigation<-guards.html
*/
const authGuard = (to, from, next)=>{
  const authStore = AuthStore();
  if( ! authStore.isLoggedIn && to.meta.authGuard == true){
    return next({name: 'Dashboard'})
  }
  else {
    next()
  }
};


router.beforeEach(usernameCreationGuard);
router.beforeEach(authGuard);

export default router
