<template>
    <ion-modal ref="sheetmodal" mode="ios" :initial-breakpoint="breakpoint" :breakpoints="fixed ? [breakpoint] : breakpoints"
        :handle="!fixed"
        :backdrop-dismiss="!fixed"
        :backdrop-breakpoint="fixed ? 1-breakpoint:0"
    >
        <ion-header>
            <slot name="header"></slot>
        </ion-header>

        <ion-content>
            <ion-item v-if="!fixed" color="none" lines="none">
                <ion-buttons slot="end">
                    <ion-button @click="dismiss()" slot="end" size="large">
                        <ion-icon slot="icon-only" :icon="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
            <slot></slot>
        </ion-content>

        <ion-footer>
            <slot name="footer"></slot>
        </ion-footer>
    </ion-modal>

</template>

<script setup lang="ts">
    import { inject, ref } from 'vue';
    import { close } from 'ionicons/icons';

    const sheetmodal = ref()
    const isDesktop = inject('isDesktop')

    const emit = defineEmits<{
        (e: 'onDismiss'): void
    }>();

    const props = defineProps({
        breakpoint: {
            type: Number,
            default: 0.5
        },
        breakpoints: {
            type: Array<Number>,
            default: [0, 0.25, 0.3, 0.50, 0.75]
        },
        fixed: {
            type: Boolean,
            default: false
        },
        desktopMaxWidth: {
            type: String,
            default: '600px'
        },
    });

    defineExpose({
        dismiss,
        open,
    });

    function open(){
        sheetmodal.value.$el.present();
    }
    function dismiss(){
        sheetmodal.value.$el.dismiss();
        emit('onDismiss');
    }
</script>

<style scoped>
    ion-modal {
        justify-content: v-bind("isDesktop ? 'right':'center'");
        --width: 100%;
        --max-width: v-bind(desktopMaxWidth);
    }
</style>