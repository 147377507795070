<template>
    <ion-page>
        <ion-header>
            <ion-toolbar v-if="isMobile">
                <ion-title><h1>{{$t('Community')}}</h1></ion-title>
            </ion-toolbar>
            <ion-toolbar v-else>
                <ion-button fill="outline" shape="round" size="large">
                    <ion-icon slot="start" :icon="map"></ion-icon>
                    {{$t('Map')}}
                </ion-button>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-title><h1>Les roadbooks uploadés<br> par la communauté !!
            </h1></ion-title>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { caretBack, map, menu } from 'ionicons/icons';
import router from '@/router';
import BackNavButton from '@/components/BackNavButton.vue';
import { inject } from 'vue';

const isMobile = inject('isMobile')
</script>

<style scoped>
</style>