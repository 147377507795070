<template>
    <ion-page>
        <ion-content class="div-center">
            <ion-title>Help Center</ion-title>
        </ion-content>
    </ion-page>
</template>
<script setup lang="ts">
</script>
<style scoped>
</style>