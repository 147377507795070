<template>
    <ion-modal ref="roadbookInfoModal" v-on:keyup.escape="dismiss" :can-dismiss="true">
        <ion-header>
            <ion-toolbar>
                <InlineDocumentFieldEdit :autoUpdate="!roadbookStore.isNew()"  @onUpdated="roadbookStore.value.title=$event"
                    :documentAPI="roadbookAPI" :document="roadbookStore.value" :documentId="roadbookStore.id" 
                    fieldName="title" placeholder="Add a title here">
                </InlineDocumentFieldEdit>
                <ion-button @click="toggleFavorite" :disabled="!roadbook" slot="end" size="large" fill="clear" shape="round">
                    <ion-icon v-if="roadbook.favorite" slot="icon-only" :icon="heart" color="tertiary"></ion-icon>
                    <ion-icon v-else slot="icon-only" :icon="heartOutline" size="large"></ion-icon>
                </ion-button>
                <ion-button @click="dismiss()" slot="end" size="large" fill="clear" shape="round">
                    <ion-icon slot="icon-only" :icon="close" color="light"></ion-icon>
                </ion-button>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-toolbar>
                    <ion-segment v-model="infoSegment">
                        <ion-segment-button value="informations" layout="icon-start">
                            <ion-icon :icon="informationCircleOutline"></ion-icon>
                            <ion-label>{{$t('Informations')}}</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="map" layout="icon-start">
                            <ion-icon :icon="map"></ion-icon>
                            <ion-label>{{$t('Map')}}</ion-label>
                        </ion-segment-button>
                    </ion-segment>
            </ion-toolbar>

            <div v-if="roadbookStore.id && infoSegment == 'informations'">
                <ion-row>
                    <ion-col size="6">
                        <ion-row>

                            <ion-col size="12">
                                <ion-card>
                                    <ion-card-content>
                                <ion-item>
                                    <ion-icon slot="start" :icon="informationCircle" color="light"></ion-icon>
                                    <ion-label><h2>{{ $t('Details') }}</h2></ion-label>
                                </ion-item>
                                {{$t('total_distance')}}: {{roadbook.total_distance}}<br>
                                {{$t('estimated_time')}}: {{roadbook.estimated_time}}<br>
                                {{$t('created_date')}}: {{roadbook.created_date}}<br>
                                {{$t('updated_date')}}: {{roadbook.updated_date}}<br>
                                {{$t('roundtrip')}}: {{roadbook.roundtrip}}<br>
                                {{$t('hightway')}}: {{roadbook.hightway}}<br>
                                <div v-if="roadbook.tollgate">
                                    {{$t('tollgate_cost')}}: {{roadbook.tollgate_cost}}<br>
                                </div>
                            </ion-card-content>
                            </ion-card>
                        </ion-col>
                    </ion-row>
                </ion-col>
                </ion-row>
            </div>

            <div v-if="roadbookStore.id && infoSegment == 'map'">
                <ion-row>
                    <ion-col size="12">
                        <ion-card>
                            <ion-card-content class="ion-no-padding">
                                <ion-item v-if="!roadbook || !roadbook.waypoints || roadbook.waypoints.length == 0" lines="none" color="primary" class="ion-no-padding">
                                    <ion-icon slot="start" :icon="informationCircleOutline" class="ion-margin-start"></ion-icon>
                                    <ion-text >
                                        <h2>{{$t('No waypoints have been set for this Roadbook')}}</h2>
                                    </ion-text>
                                </ion-item>
                                <Map v-if="roadbook.id" ref="mapview"
                                     :interactive="false"
                                     v-model:points="roadbook.points"
                                     v-model:pointsSections="roadbook.points_sections"
                                     v-model:waypoints="roadbook.waypoints"
                                >
                                </Map>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </div>
        </ion-content>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref, onMounted, onUpdated, inject } from 'vue'
    import { refreshData } from '@/libs/userExperience'
    import { informationCircle, informationCircleOutline, heartOutline, heart, map, close } from 'ionicons/icons';
    import { BackendAPI } from '@/libs/backend';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';
    import Map from '@/components/Map.vue';
    import { defaultMapLayer } from '@/libs/tileProviders';
    import { roadbookEditStore} from '@/stores/RoadbookEditStore'

    // Component event emission
    const emit = defineEmits<{
        (e: 'dismiss'): void
        (e: 'created', data:any): void
    }>();

    defineExpose({
        dismiss,
        open,
    });

    // Store / API
    const roadbookStore = roadbookEditStore();
    const roadbookAPI = new BackendAPI('/roadbook')

    // HTML Element refs
    const roadbookInfoModal = ref();

    // Variable refs
    const roadbookId = ref('')
    const roadbook:any = ref({})
    const mapview = ref()
    const infoSegment = ref('informations')
    const isMobile = inject('isMobile')
    const isDesktop = inject('isDesktop')
    const modalHeight = ref(isDesktop? '1200px': '100%')
    const modalWidth = ref(isDesktop? '1200px': '100%')

    async function fetchRoadbook(){
        if(!roadbookId.value) return
        await roadbookStore.init(roadbookId.value); // to get all map features
        roadbook.value = roadbookStore.value;
    }

    onMounted(()=>{
        fetchRoadbook();
    });

    onUpdated(()=>{
        refreshData(fetchRoadbook);
    });

    async function open(id:any){
        roadbookId.value = id;
        fetchRoadbook();
        roadbookInfoModal.value.$el.present();
    }

    function dismiss(){
        roadbookInfoModal.value.$el.dismiss();
        emit('dismiss');
    }

    function toggleFavorite(){
        roadbook.value.favorite = !roadbook.value.favorite;
        let data = {favorite: roadbook.value.favorite};
        roadbookAPI.update(roadbookId.value, data).then((res)=>{
            roadbook.value.favorite = res.favorite;
        });
    }
    

</script>

<style scoped>
ion-modal {
    --height: v-bind(modalHeight);
    --max-height: v-bind(modalHeight);
    --max-height: v-bind(modalHeight);
    --width: v-bind(modalWidth);
    --max-width: v-bind(modalWidth);
    --max-width: v-bind(modalWidth);
}
ion-content{
    border-left: 3px solid var(--ion-color-primary);
    border-right: 3px solid var(--ion-color-primary);
    border-bottom: 3px solid var(--ion-color-primary);
}
ion-header{
    border-left: 3px solid var(--ion-color-primary);
    border-right: 3px solid var(--ion-color-primary);
    border-top: 3px solid var(--ion-color-primary);
}

</style>

