<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <BackNavButton></BackNavButton>
                <ion-title class="ion-text-uppercase">{{$t('Boomerang')}}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <PrepareDiscovery></PrepareDiscovery>
        </ion-content>
        <ion-footer>
             <ion-toolbar>
                <ion-button @click="route" expand="block" class="ion-margin" :disabled="!roadbookStore.isRoutable()">
                    {{$t('Create a Boomerang')}}
                    <MaterialSymbol name="360" class="ion-margin-start"></MaterialSymbol>
                </ion-button>
            </ion-toolbar>
        </ion-footer>
    </ion-page>

</template>

<script setup lang="ts">
    import { inject, onMounted } from 'vue';
    import BackNavButton from '@/components/BackNavButton.vue';
    import PrepareDiscovery from '@/components/PrepareDiscovery.vue';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { useRouter } from 'vue-router';
    
    // variables refs
    const isDesktop = inject('isDesktop')
    // Stores
    const roadbookStore = roadbookEditStore()
    // Vue
    const router = useRouter()

    onMounted(()=>{
        // THIS PAGE IS MOBILE ONLY
        if(isDesktop) router.replace('/roadbooks/new/edit/discovery')
    });

    function route(){
        router.push('/roadbooks/new/edit/discovery')
    };
</script>

<style scoped>



</style>