import { AuthStore } from "@/stores/AuthStore";

export async function setupAuthenticationDirectives(app:any){
    /* Usage : 
      <tag v-if-authentified></tag>       Show when user is authentified
    */
    app.directive('if-authentified', async(el:any, binding:any, vnode:any)=> {
        const authStore = AuthStore()
        if(!authStore.isAuthentified){
            vnode.el.hidden = true;
            el.style.display = 'none';
        }
    });
   
}