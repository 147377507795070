<template>
    <ion-row>
        <!-- <ion-col size="12" class="ion-text-center">
            <h1>{{$t('Create a Boomerang')}}</h1>
        </ion-col> -->
        <ion-col size="12" class="ion-padding ion-text-center">
            <ion-text color="medium" class="total-loop">
                {{ $t('Total loop') }}
            </ion-text>
            <ion-text class="distance">
                <h1>{{ distance }} km</h1>
            </ion-text>
            <ion-range v-model="distance" mode="ios" class="ion-no-padding"
                value="50" :min="minRoundtripDistanceKm" :max="maxRoundtripDistanceKm" step="10" pin="false"
                snaps="false" @ionChange="route()"
                ticks="false" 
                color="primary">
            </ion-range>
        </ion-col>


        <ion-col size="12" class="ion-text-center ion-margin-bottom">
            <LocationSelector ref="departureRef" :currentLocationAsDefault="true" @locationSelected="departure = $event; route()"
                :title="$t('Departure')" title-icon="/start.svg" ></LocationSelector>
        </ion-col>
        <!-- <ion-col size="12">
            <RouteProfileSelector class="ion-margin" :options="false" :profile="roadbookStore.currentProfile()" @changed="roadbookStore.setCurrentProfile($event)"></RouteProfileSelector>
        </ion-col> -->
        <ion-col size="12" class="compass-section">
            
            <!-- <ion-text>
                <h4>{{$t('Pick your direction')}}</h4>
            </ion-text> -->

            <Compass ref="compass" @heading="heading = $event; route()"></Compass>
        </ion-col>

    </ion-row>
    <Router ref="routing" :type="roadbookType"></Router>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { geoLocationStore } from "@/stores/geoLocationStore";
    import LocationSelector from '@/components/LocationSelector.vue';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import Compass from '@/components/Compass.vue';
    import Router from './Router.vue';


    // HTML references
    const routing = ref()
    const compass = ref()

    // Variables
    const distance = ref(50);
    const heading = ref(0);
    const departure:any = ref(undefined)
    const minRoundtripDistanceKm:any = inject('minRoundtripDistanceKm')
    const maxRoundtripDistanceKm:any = inject('maxRoundtripDistanceKm')

    const geoLocation = geoLocationStore();
    const roadbookStore = roadbookEditStore();
    const roadbookType ='discovery'

    onMounted(()=>{
        geoLocation.requestPosition()
        roadbookStore.init('new', roadbookType)
        roadbookStore.options.automatic_feet = true;
    })


    function route(){
        if(!departure.value) return

        roadbookStore.clear();
        roadbookStore.value.roundtrip_heading = heading.value
        roadbookStore.value.roundtrip_distance = distance.value * 1000 // to meters
        roadbookStore.addWaypoint(departure.value)

        routing.value.reset();
        routing.value.updateRouting();
    }  

</script>

<style scoped>
    .compass-section {
        padding-left: 10%;
        padding-right: 10%;
    }

    .total-loop {
        font-size: 2rem;
    }
    .distance h1 { 
        font-size: 3rem;
        margin-top: 0px;
    }
    ion-footer ion-button{
        font-size: 1.2rem;
    }

</style>