<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <BackNavButton ref="exit" slot="start" resetButtons :haveChanges="history && history.haveHistory()" @back="roadbookStore.$reset()"></BackNavButton>
                <InlineDocumentFieldEdit v-if-desktop :autoUpdate="!roadbookStore.isNew()"  @onUpdated="roadbookStore.value.title=$event"
                    :documentAPI="roadbookAPI" :document="roadbookStore.value" :documentId="roadbookStore.id" 
                    fieldName="title" placeholder="Add a title here">
                </InlineDocumentFieldEdit>
                <MapHistory ref="history" slot="end"></MapHistory>
                <MapEditHelpModal ref="userHelp" slot="end"></MapEditHelpModal>
                
                <MapSave ref="save" slot="end" :canSave="history && history.haveHistory()" @saving="loading = $event"></MapSave>
                
                <ion-progress-bar v-if="calculating || loading" type="indeterminate" color="primary"></ion-progress-bar>
            </ion-toolbar>
        </ion-header>

        <ion-content class="ion-no-padding">
            <Map ref="map"
                 v-model:points="roadbookStore.value.points"
                 v-model:pointsSections="roadbookStore.value.points_sections"
                 v-model:waypoints="roadbookStore.value.map_waypoints"
            >
            </Map>
           
            <MapSearchSheet ref="search" @focusPlace="map.showPlace($event)">
            </MapSearchSheet>
    
            <MapLocateButton ref="locate" @focus="map.showCurrentLocation()"></MapLocateButton>

            <MapSettingsModal ref="mapSettingsModal"></MapSettingsModal>
            
            <MapProfileSelector></MapProfileSelector>

            <Router ref="routing" :type="RoadbookType"
                @onCalculatingRoute="calculating = true" @onRouteCalculated="calculating = false">
            </Router>

        </ion-content>
        
    </ion-page>
    
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';

    import Map from '@/components/Map.vue';
    import MapEditHelpModal from '@/components/MapEditHelpModal.vue';
    import MapSettingsModal from '@/components/MapSettingsModal.vue';
    import Router from '@/components/Router.vue';
    import MapSearchSheet from '@/components/MapSearchSheet.vue';
    import MapProfileSelector from '@/components/MapProfileSelector.vue';
    import BackNavButton from '@/components/BackNavButton.vue';
    import MapHistory from '@/components/MapHistory.vue';
    import MapLocateButton from '@/components/MapLocateButton.vue';
    import MapSave from '@/components/MapSave.vue';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore'
    import { geoLocationStore } from '@/stores/geoLocationStore';
    import { roadbookAPI } from '@/libs/backend';

    // Data stores
    const roadbookStore = roadbookEditStore();
    const geoLocation = geoLocationStore();
    // variables refs
    const loading = ref(false)
    const calculating = ref(false)
    const route = useRoute();
    const roadbookID:any = route.params.id || 'new';
    const RoadbookType ='routing'
    var userLocationFocused = false;
    
    // HTML components refs
    const locate = ref();
    const map = ref();
    const userHelp = ref();
    const mapSettingsModal = ref();
    const search = ref();
    const history = ref();
    const exit = ref();
    const save = ref();
    const routing = ref();

    onMounted(()=>{
        // TODO get favorite map layer if present in user settings
        roadbookStore.init(roadbookID, RoadbookType);
        focusUserLocation();
    });
    
    watch(() => geoLocation.location, () => {
        focusUserLocation();
    });

    watch(() => roadbookStore.initialized, () => {
        focusUserLocation();
    });

    function focusUserLocation(){
        if(!userLocationFocused && geoLocation.location && roadbookStore.initialized && !roadbookStore.value.points.length) {
            setTimeout(()=>{ // let time to map initialization
                map.value.showCurrentLocation();
                userLocationFocused = true;
            },200)
        }
    }


    document.addEventListener("keydown", (event:KeyboardEvent) => {
        if((navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey)){
            // Replace browser ctrl + S key event
            if (event.keyCode == 83) {
                event.preventDefault();
                if(save.value) save.value.save()
            }
            // Replace browser ctrl + F key event
            if (event.keyCode == 70) {
                event.preventDefault();
                if(search.value) search.value.open();
            }
            // Replace browser ctrl + Z key event
            if (event.keyCode == 90) {
                event.preventDefault();
                if(history.value) history.value.undo()
            }
            // Replace browser ctrl + Y key event
            if (event.keyCode == 89) {
                event.preventDefault();
                if(history.value) history.value.redo()
            }
        }
    }, false);

</script>

<style scoped>
    ion-toolbar ion-title:hover {
        cursor: pointer;
    }

    ion-button.btn-icon-xl {
        width: 70px !important;
        height: 70px !important;
        font-size: 70px !important;
    }

    ion-button.btn-icon-md {
        width: 55px !important;
        height: 55px !important;
        font-size: 55px !important;
    }


</style>

