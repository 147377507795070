<template>
    <ContentTemplate>
        <ion-content>
            <ion-item color="none" lines="none">
                <ion-buttons slot="end">
                    <ion-button @click="newTripModal.$el.dismiss()" slot="end" size="large">
                        <ion-icon slot="icon-only" :icon="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
            <ion-card button @click="createRoadbook('flash')">
                <ion-card-header>
                    <ion-row>
                        <ion-col size="2">
                            <MaterialSymbol name="flash_on" color="primary" class="trip-card-icon"></MaterialSymbol>
                        </ion-col>
                        <ion-col size="8">
                            <ion-card-title>{{$t('Flash Trip')}}</ion-card-title>
                            <ion-text>{{ $t('Route, upload, ride') }}</ion-text>
                        </ion-col>
                        <ion-col size="2">
                            <ion-buttons class="select-card-icon">
                                <ion-button>
                                    <ion-icon slot="icon-only" color="primary" :icon="chevronForward"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-col>
                    </ion-row>
                </ion-card-header>
                <ion-card-content>
                </ion-card-content>
            </ion-card>
            <ion-card button @click="createRoadbook('discovery')">
                <ion-card-header>
                    <ion-row>
                        <ion-col size="2">
                            <MaterialSymbol name="360" color="primary" class="trip-card-icon"></MaterialSymbol>
                        </ion-col>
                        <ion-col size="8">
                            <ion-card-title>{{$t('Boomerang')}}</ion-card-title>
                            <ion-text>{{ $t('Discover a new route around you') }}</ion-text>
                        </ion-col>
                        <ion-col size="2">
                            <ion-buttons class="select-card-icon">
                                <ion-button>
                                    <ion-icon slot="icon-only" color="primary" :icon="chevronForward"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-col>
                    </ion-row>
                </ion-card-header>
            </ion-card>
            
            <ion-card button @click="createRoadbook('routing')">
                <ion-card-header>
                    <ion-row>
                        <ion-col size="2">
                            <MaterialSymbol name="edit_road" color="primary" class="trip-card-icon"></MaterialSymbol>
                        </ion-col>
                        <ion-col size="8">
                            <ion-card-title>{{$t('Expert')}}</ion-card-title>
                            <ion-text>{{ $t('Edit your route with roadbook requirements') }}</ion-text>
                        </ion-col>
                        <ion-col size="2">
                            <ion-buttons class="select-card-icon">
                                <ion-button>
                                    <ion-icon slot="icon-only" color="primary" :icon="chevronForward"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-col>
                    </ion-row>
                </ion-card-header>
            </ion-card>
        </ion-content>
    </ContentTemplate>

    <ion-modal v-if="isMobile" ref="newTripModal" mode="ios" :initial-breakpoint="0.75" :breakpoints="[0, 0.75]">
        <ReuseContentTemplate></ReuseContentTemplate>
    </ion-modal>

    <ion-modal v-else ref="newTripModal" mode="ios">
        <ReuseContentTemplate></ReuseContentTemplate>
    </ion-modal>

</template>

<script setup lang="ts">
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { chevronForward, close } from 'ionicons/icons';
    import { inject, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { createReusableTemplate } from '@vueuse/core'

    const [ContentTemplate, ReuseContentTemplate] = createReusableTemplate()

    const emit = defineEmits<{
        (e: 'onDismiss'): void
    }>();

    defineExpose({
        dismiss,
        open,
    });

    const router = useRouter()
    const isMobile = inject('isMobile')

    const newTripModal = ref();

    function open(){
        newTripModal.value.$el.present();
    }
    function dismiss(){
        newTripModal.value.$el.dismiss();
        emit('onDismiss');
    }

    function createRoadbook(type:string){
        newTripModal.value.$el.dismiss();
        router.push({path: `/roadbooks/prepare/${type}`})
    }
</script>

<style scoped>

    .trip-card-icon {
        font-size: 2rem !important
    }

    .select-card-icon {
        margin-top: 20px;
    }
</style>