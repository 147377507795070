<template>
    <div class="container">
        <ion-segment v-model="selectedProfile" @ionChange="segmentChange">
            <!-- <ion-segment-button value="highway">
                <MaterialSymbol name="flash_on"></MaterialSymbol>
            </ion-segment-button>
            <ion-segment-button value="no_highway">
                <MaterialSymbol name="conversion_path"></MaterialSymbol>
            </ion-segment-button>
            <ion-segment-button value="super_curvy">
                <MaterialSymbol name="gesture"></MaterialSymbol>
            </ion-segment-button>

            <ion-segment-button value="comment" disabled="true" class="selected-label">
                <ion-text v-if="selectedProfile == 'highway'" color="light">{{ $t('Fastest') }}</ion-text>
                <ion-text v-if="selectedProfile == 'no_highway'" color="light">{{ $t('Curvy') }}</ion-text>
                <ion-text v-if="selectedProfile == 'super_curvy'" color="light">{{ $t('Super') }}<br>{{ $t('curvy') }}</ion-text>
            </ion-segment-button> -->
            <ion-segment-button value="highway">
                <MaterialSymbol name="flash_on"></MaterialSymbol>
                <ion-text :color="selectedProfile == 'highway'? 'primary':'light'">{{ $t('Fastest') }}</ion-text>
            </ion-segment-button>
            <ion-segment-button value="no_highway">
                <MaterialSymbol name="conversion_path"></MaterialSymbol>
                <ion-text :color="selectedProfile == 'no_highway'? 'primary':'light'">{{ $t('Curvy') }}</ion-text>
            </ion-segment-button>
            <ion-segment-button value="super_curvy">
                <MaterialSymbol name="gesture"></MaterialSymbol>
                <ion-text :color="selectedProfile == 'super_curvy'? 'primary':'light'">{{ $t('Super curvy') }}</ion-text>
            </ion-segment-button>
        </ion-segment>

        <div v-if="options || isDesktop">
            <ion-item color="none" lines="none" class="ion-margin-top">
                <MaterialSymbol name="add_road" :color="selectedProfile == 'highway' ? 'light':'medium'" class="ion-margin-end"></MaterialSymbol>
                <ion-toggle v-model="roadbookStore.value.hightway" :disabled="selectedProfile != 'highway'">{{ $t('Motorway') }}</ion-toggle>
            </ion-item>
            <ion-item color="none" lines="none">
                <MaterialSymbol name="payments" :color="selectedProfile == 'highway' ? 'light':'medium'" class="ion-margin-end"></MaterialSymbol>
                <ion-toggle v-model="roadbookStore.value.tollgate" :disabled="selectedProfile != 'highway'">{{ $t('Tollgate') }}</ion-toggle>
            </ion-item>
        </div>

        <ion-row v-if="validateButton">
            <ion-col size="12">
                <ion-button @click="emit('changed', selectedProfile)" expand="block">
                    {{$t('Apply profile')}}
                </ion-button>
            </ion-col>
        </ion-row>
    </div>
</template>

<script setup lang="ts">
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { inject, onMounted, Ref, ref } from 'vue';

    const selectedProfile:Ref<string> = ref('')
    const isDesktop = inject('isDesktop')
    const roadbookStore = roadbookEditStore()

    const emit = defineEmits<{
        (e: 'changed', profile:string): void
    }>();


    const props = defineProps({
        profile: {
            type: String,
            default: 'highway',
        },
        options: {
            type: Boolean,
            default: true,
        },
        validateButton: {
            type: Boolean,
            default: false,
        },
    });

    onMounted(()=>{
        selectedProfile.value = props.profile;
    })

    function segmentChange(){
        if(!props.validateButton){ // user have to trigger change with validate button
            emit('changed', selectedProfile.value)
        }
    }
</script>

<style scoped>
    .container {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
    
    ion-segment {
        --background: var(--ion-color-anthracite);
    }

    i {
        font-size: 3rem !important;
    }
    .segment-button-checked {
        /* background: var(--ion-color-light) */

        /* background: var(--ion-color-light); */
        /* --indicator-color: var(--ion-color-petrol); */
        --indicator-color: var(--ion-color-petrol);
    }
    .selected-label {
        opacity: 1;
    }
    .selected-label ion-text{
        min-width: 140px;
        font-size: 1rem;
        color: var(--ion-color-primary) !important;
    }
    ion-card {
        border: unset !important;
    }
</style>