<template>
    <div >
        <ion-row>
            <ion-col size="12" class="search-section">
                <ion-item-sliding :disabled="!removeButton">
                    <ion-item v-if="(!selectedLocation && autoSelectCurrentLocation) || (selectedLocation && selectedLocation.userLocation)" button color="success" @click="edit">
                        <ion-icon slot="start" :src="titleIcon" size="large"></ion-icon>
                        <p>
                            <ion-label class="small">{{ title }}</ion-label>
                            <ion-label>{{$t('My location')}}</ion-label>
                        </p>
                        <ion-spinner v-if="!selectedLocation" slot="end" name="crescent"></ion-spinner>
                        <ion-icon v-else slot="end" :icon="locate" class="ion-margin-start"></ion-icon>
                    </ion-item>
                    <ion-item v-else-if="selectedLocation" button color="success" @click="edit">
                        <ion-icon slot="start" :src="titleIcon" size="large"></ion-icon>
                        <p>
                            <ion-label class="small">{{ title }}
                            </ion-label>
                            <ion-label>{{selectedLocation.address || selectedLocation.label}}</ion-label>
                        </p>
                        <ion-icon v-if="selectedLocation.favorite" :icon="star" class="ion-margin-start" slot="end"></ion-icon>
                        <ion-icon v-else-if="selectedLocation.map" :icon="locationOutline" class="ion-margin-start" slot="end"></ion-icon>
                    </ion-item>
                    <ion-input v-else type="text" ref="fakeSearchbar" :value="searchText" inputmode="search" :debounce="300" @click="edit"
                        :placeholder="$t('Search')"  class="searchbar">
                        <ion-icon slot="start" :src="titleIcon" size="large"></ion-icon>
                    </ion-input>

                    <ion-item-options v-if="removeButton">
                        <ion-button @click="remove"  color="danger">
                            <ion-icon :icon="trash"></ion-icon>
                            {{ $t('Remove') }}
                        </ion-button>
                    </ion-item-options>
                </ion-item-sliding>
            </ion-col>
        </ion-row>

        <ion-modal ref="selectorModal" mode="ios" :animated="true" :enter-animation="enterAnimation" :leave-animation="leaveAnimation">
            <ion-content>
                <ion-input type="text" ref="searchbar" v-model="searchText" inputmode="search" :debounce="300"
                    :placeholder="$t('Your search')"  @ionInput="searchLocation($event)" class="searchbar searchbar-modal">
                    <ion-icon slot="start" :src="titleIcon" size="large"></ion-icon>
                    <ion-chip v-if="searchText.length" slot="end" class="searchbar-clear" @click="searchText = ''">
                        <ion-icon :icon="closeIcon"></ion-icon>
                    </ion-chip>
                </ion-input>

                <ion-row>
                    <ion-col v-if="searching" size="12">
                        <ion-progress-bar type="indeterminate"></ion-progress-bar>
                    </ion-col>
                    <ion-col size="12" v-if="!searchText">
                        <ion-button v-if="locationStore.hasPermission || locationStore.hasTemporalyAccepted" fill="clear" @click="selectUserLocation">
                            <MaterialSymbol name="location_searching" color="primary" class="ion-margin-end"></MaterialSymbol>
                            <ion-label color="primary">{{$t('My location')}}</ion-label>
                        </ion-button>
                        <ion-button v-else-if="locationStore.hasDenied" fill="clear" @click="locationStore.requestPosition()" color="warning">
                            <MaterialSymbol name="location_searching" color="warning" class="ion-margin-end"></MaterialSymbol>
                            <ion-text>Location access is denied</ion-text>
                        </ion-button>
                        <ion-button v-else fill="clear" @click="locationStore.requestPosition()" color="warning">
                            <MaterialSymbol name="location_searching" color="warning" class="ion-margin-end"></MaterialSymbol>
                            <ion-text>Request location access</ion-text>
                        </ion-button>

                    </ion-col>
                    <ion-col size="12" class="search-results-section">
                        <ion-list v-if="searchText">
                            <ion-item v-if="!searching && !searchResults.length" color="none">
                                <ion-label>{{$t('No result')}}</ion-label>
                            </ion-item>
                            <ion-item v-for="(result, index) of searchResults" button @click="selectSearch(searchItems[index].location())" color="none">
                                <NominatimLocation ref="searchItems" :location="result"></NominatimLocation>
                            </ion-item>
                        </ion-list>
                    </ion-col>
                </ion-row>

                <ion-card v-if="! searchText.length" class="other-locations-card">
                    <ion-row>
                        <ion-col size="12">
                            <ion-segment v-model="searchSegment" class="location-segments">
                                <ion-segment-button value="history">
                                    <MaterialSymbol name="history"></MaterialSymbol>
                                    <ion-label>{{ $t('History') }}</ion-label>
                                </ion-segment-button>
                                <ion-segment-button v-if="authStore.isLoggedIn" value="favorite">
                                    <MaterialSymbol name="star"></MaterialSymbol>
                                    <ion-label>{{ $t('Favorite') }}</ion-label>
                                </ion-segment-button>
                                <ion-segment-button value="map">
                                    <MaterialSymbol name="map"></MaterialSymbol>
                                    <ion-label>{{ $t('Pick') }}</ion-label>
                                </ion-segment-button>
                            </ion-segment>
                        </ion-col>
                        
                        <ion-col v-if="searchSegment == 'history'" size="12">
                            <ion-text v-if="!searchStore.history.length" class="ion-text-center">
                                <h4 class="ion-margin">{{$t('No search history')}}</h4>
                            </ion-text>
                            <ion-list v-if="searchStore.history.length">
                                <ion-item v-for="history of searchStore.history" button @click="selectSearchHistory(history)">
                                    <MaterialSymbol name="history" class="ion-margin-end"></MaterialSymbol>
                                    <ion-label>{{history.address}}</ion-label>
                                </ion-item>
                            </ion-list>
                        </ion-col>

                        <ion-col v-if="searchSegment == 'favorite' && authStore.isLoggedIn" size="12">
                            <ion-text v-if="!favoriteStore.places.length" class="ion-text-center">
                                <h4 class="ion-margin">{{$t('No favorite saved for now')}}</h4>
                            </ion-text>
                            <ion-list v-if="favoriteStore.places.length">
                                <ion-item v-for="favorite of favoriteStore.places" button @click="selectFavorite(favorite)">
                                    <MaterialSymbol name="star" class="ion-margin-end"></MaterialSymbol>
                                    <ion-label>{{favorite.label}}</ion-label>
                                    <ion-label>{{favorite.address}}</ion-label>
                                </ion-item>
                            </ion-list>
                        </ion-col>

                        <ion-col v-if="searchSegment == 'map'" size="12">
                            <MapPicker :height="mapHeight" @selected="selectMap($event)"></MapPicker>
                        </ion-col>
                    </ion-row>
                </ion-card>
            </ion-content>

            <ion-footer>
                <ion-toolbar>
                    <ion-button v-if="removeButton" @click="remove" expand="block" color="danger" fill="clear" size="small" class="ion-margin">
                        <ion-icon slot="start" :icon="trashOutline"></ion-icon>
                        {{ $t('Remove') }}
                    </ion-button>
                    <ion-button @click="close" expand="block" class="close-button">
                        <ion-icon slot="start" :icon="closeIcon"></ion-icon>
                        {{ $t('Close') }}
                    </ion-button>
                </ion-toolbar>
            </ion-footer>
        </ion-modal>


    </div>
</template>

<script setup lang="ts">
    import { onMounted, onUpdated, ref, watch, computed } from 'vue';
    import MapPicker from './MapPicker.vue';
    import NominatimLocation from './geocoder/NominatimLocation.vue';
    import { locationAPI } from '@/libs/backend';
    import { favoritePlaceStore } from '@/stores/FavoritePlaceStore';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { locate, star, warning, warningOutline, close as closeIcon, trashOutline, location, locationOutline, trash } from 'ionicons/icons';
    import { focusInput } from '@/libs/userExperience';
    import { searchPlaceStore } from '@/stores/SearchPlaceStore';
    import { geoLocationStore } from '@/stores/geoLocationStore';
    import { createAnimation } from '@ionic/vue';
    import { AuthStore } from '@/stores/AuthStore';
    

    // variables refs
    const selectedLocation:any = ref(undefined);
    const searchSegment = ref('history')
    const searching = ref(false)
    const searchResults = ref([])
    const searchText = ref('') // used to save search text state
    const mapHeight = window.innerHeight - 420
    const autoSelectCurrentLocation = computed(()=> props.currentLocationAsDefault && locationStore.hasPermission)

    // HTML components refs
    const searchbar = ref()
    const fakeSearchbar = ref()
    const searchItems = ref()
    const selectorModal = ref()

    // Store
    const favoriteStore = favoritePlaceStore()
    const searchStore = searchPlaceStore()
    const locationStore = geoLocationStore()
    const authStore = AuthStore();

    const emit = defineEmits<{
        (e: 'cancel'): void,
        (e: 'editing'): void,
        (e: 'remove'): void,
        (e: 'locationSelected', location:number[]): void
    }>();

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        titleIcon: {
            type: String,
            required: true
        },
        removeButton: {
            type: Boolean,
            default: false
        },
        currentLocationAsDefault: {
            type: Boolean,
            default: false
        },
    });

    defineExpose({
        close,
    });

    onMounted(()=>{
        favoriteStore.init()
        searchStore.init()
        locationStore.requestPosition()
        autoLocation()
    });
    
    function autoLocation(){
        if(props.currentLocationAsDefault && !selectedLocation.value){
            if(locationStore.location) {
                selectUserLocation()
            }
            else {
                watch(() => locationStore.location, () => {
                    selectUserLocation()
                }, {once:true});
            }
        }
    }

    const enterAnimation = (baseEl: HTMLElement) => {
        const root:any = baseEl.shadowRoot;

        const backdropAnimation = createAnimation()
        .addElement(root.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = createAnimation()
            .addElement(root.querySelector('.modal-wrapper'))
            .fromTo('transform', 'translateX(1000px)', 'translateX(0px)')

        return createAnimation()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(200)
        .addAnimation([backdropAnimation, wrapperAnimation])
    };

    const leaveAnimation = (baseEl:any) => {
        return enterAnimation(baseEl).direction('reverse');
    };

    function edit(){
        selectorModal.value.$el.present()
        focusInput(searchbar, 350)
        emit('editing');
    }

    function close(){
        if(searchbar.value) searchbar.value.$el.blur();
        selectorModal.value.$el.dismiss()
    }

    function remove(){
        emit('remove')
        close()
    }

    function selectSearch(location:any){
        selectedLocation.value = location;
        searchStore.addSearchHistory(location.coordinates, location.address, location.label)
        emit('locationSelected', location.coordinates);
        close()
    }

    function selectSearchHistory(historyLocation:any){
        selectedLocation.value = historyLocation;
        emit('locationSelected', historyLocation.coordinates);
        close()
    }

    function selectFavorite(favorite:any){
        selectedLocation.value = favorite; 
        selectedLocation.value.favorite = true
        emit('locationSelected', favorite.coordinates);
        close()
    }

    function selectUserLocation(){
        if(searchText.value) return // prevent from quick user tiping wiht 'autoFocusUserLocation' option
        selectedLocation.value = locationStore.location; 
        selectedLocation.value.userLocation = true
        emit('locationSelected', selectedLocation.value);
        close()
    }


    function selectMap(mapLocation:any){
        selectedLocation.value = mapLocation; 
        selectedLocation.value.map = true
        searchStore.addSearchHistory(mapLocation.coordinates, mapLocation.address, mapLocation.label)
        emit('locationSelected', mapLocation.coordinates);
        close()
    }

    async function searchLocation(event:any) {
        searching.value = true;
        let query = event.target.value.toLowerCase();
        searchResults.value = await locationAPI.search(query);
        searching.value = false;
    }

</script>


<style scoped>
    .location-title {
        font-size: 20px;
    }
    .search-card {
        margin: 0 0 0 0;
    }

    .search-section ion-item,
    .search-results-section ion-item  {
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 10px;
    }
    .search-section ion-list,
    .search-results-section ion-list {
        background: unset;
    }
    .search-section ion-list ion-item {
        border-radius: 0px;
        --border-color: var(--ion-color-step-100)
    }
    
    .other-locations-card {
        margin-top: 10px;
    }

    .small {
        font-size:12px;
        --color: var(--ion-color-light);
    }

    ion-input.searchbar {
        margin-left: 10px;
        margin-right: 10px;
        width: unset !important;
        --background: var(--ion-color-petrol);
        --color: var(--ion-color-light);
        --placeholder-color: var(--ion-color-light);
        --placeholder-opacity: 0.8;

        --padding-bottom: 10px;
        --padding-end: 10px;
        --padding-start: 10px;
        --padding-top: 10px;

        --border-radius: 10px;
    }
    ion-input.searchbar-modal {
        margin-top: 40px;
        margin-left: 15px;
        margin-right: 15px;
    }
    ion-input.searchbar ion-icon{
        height: 30px !important;
    }
    .searchbar-clear,
    .searchbar-clear ion-icon {
        margin-left: 1px;
        padding: 1px 1px 1px 1px;
        width: 25px;
        background-color: var(--ion-color-petrol);
    }
    .close-button {
        --background: var(--ion-color-step-900)
    }
</style>