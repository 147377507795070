import { defineStore } from 'pinia'
import { deviceAPI } from '@/libs/backend'

/* Sorage for Roabook edition on map pages */
export const DevicesStore = defineStore("devices", {
    state: () => ({
        devices: <Array<any>>[],
        initialized: false,
    }),
    getters: {
        verifiedDevices(state){
            return state.devices.filter((d:any)=>d.verified == true)
        }
    },
    actions: {
        async fetch() {
            if(this.initialized) return
            deviceAPI.disableNextErrorToast()
            this.devices = await deviceAPI.all();
        },
        async init() {
            if(this.initialized) return
            this.$reset();
            await this.fetch()
            this.initialized = true;
        },
        async refresh(){
            this.initialized = false;
            await this.init();
        },

        isRoadbookUploadPending(roadbookID:string){
            for(let device of this.devices){
                for(let roadbook of device.roadbooks){
                    if(roadbook.id == roadbookID) 
                        return true
                }
            }
        }
    },
})