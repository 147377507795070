<template>
        <ion-content id="dashboard" :fullscreen="true">
            <div id="menu-logo" class="logo-center">
                <img src="/tripymap_the_route.svg" alt="tripymap the route" class="ion-padding"/>
            </div>
            <ion-row id="card-menu">
                <ion-col size="6" class="ion-no-padding">
                    <ion-card color="petrol" button fill="outline" @click="newTripModal.$el.present()" class="menu-card">
                        <ion-card-content class="ion-text-center">
                            <ion-text color="light">
                                <MaterialSymbol name="add_location_alt" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Create a trip')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
                <ion-col size="6" class="ion-no-padding">
                    <ion-card color="petrol" button fill="outline" router-link="/community" class="menu-card">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <MaterialSymbol name="signpost" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Explore')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <ion-col size="6" class="ion-no-padding">
                    <ion-card color="petrol" button fill="outline" router-link="/roadbooks" class="menu-card">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <MaterialSymbol name="route" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Roadbooks')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <ion-col size="6" class="ion-no-padding">
                    <ion-card color="petrol" button fill="outline" router-link="/help" class="menu-card">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <MaterialSymbol name="info" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Help center')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <!-- <ion-col v-if="!authStore.isLoggedIn"  size="12" class="ion-padding ion-text-center">
                    <ion-button fill="outline" size="medium" expand="block" router-link="/login">
                        <MaterialSymbol slot="start" class="ion-margin-end" name="account_circle"></MaterialSymbol>
                        {{$t('Login')}}
                    </ion-button>
                    <ion-text color="primary" >
                        {{$t('create an account for more features')}}
                    </ion-text>
                </ion-col> -->
            </ion-row>


            <TripModal ref="newTripModal"></TripModal>
        </ion-content>

</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import TripModal from '@/components/TripModal.vue';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { AuthStore } from '@/stores/AuthStore';

    const newTripModal = ref();
    const authStore = AuthStore();

</script>


<style scoped>
    #menu-logo {
        width: 100%;
        height: 40% !important;

        --background: none;
        background-image: linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(var(--ion-color-anthracite-rgb), 1) 100%), url("/backgrounds/dashboard.jpg")  !important;
        background-size: 100% auto !important;
        background-position: top center !important;
        background-repeat: no-repeat !important; 
    }
    #menu-logo img {
        position: relative;
        top: 90%;
        padding-left: 7%;
        padding-right: 7%;
        margin-left: auto;
        margin-right: auto;
    }

    #card-menu {
        position: absolute;
        top: 48%;
        padding-left: auto;
        padding-right: auto;
        width: 100%;
    }
    .menu-card {
        margin: 4%;
    }

    .trip-button {
        border-radius: 10px;
        border: 1px var(--ion-color-light) solid;
        font-size: 25px;
    }

    .menu-button {
        border-radius: 10px;
        --background: none;
    }

    .home-icon {
        font-size: 3em !important;
    }

</style>