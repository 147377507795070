<template>
    <!-- This component have no UI -->
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from 'vue';
    import { graphhopperAPI } from '@/libs/backend';
    import { switchLatLng, extractLineStrings } from '@/libs/geoUtils'
    import jq from 'jqts';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';

    var loading = ref(false)
    const response:any = ref(undefined);
    const roadbookStore = roadbookEditStore();

    const emptyResponse = {points: [], points_sections: [], waypoints:[], bbox:[]}

    const props = defineProps({
        type: {
            type: String,
            required: true
        },
    });

    defineExpose({
        reset,
        updateRouting,
        loading,
        response
    });
    
    const emit = defineEmits<{
        (e: 'onCalculatingRoute'): void
        (e: 'onRouteCalculated', data:any): void
    }>();


    function calculating(bool:boolean){
        if(bool){
            loading.value = true;
            emit("onCalculatingRoute")
        }
        else{
            loading.value = false;
            emit("onRouteCalculated", response)
        }
    }

    var cmpWapoints:any = JSON.stringify(roadbookStore.value.map_waypoints);
    onMounted(()=>{
        cmpWapoints = JSON.stringify(roadbookStore.value.map_waypoints);
    })

    function reset(){
        cmpWapoints = ''
        response.value = undefined
    }

    async function updateRouting(){
        if(!roadbookStore.initialized || !roadbookStore.value.map_waypoints.length) return

        let mapWaypoints:string = JSON.stringify(roadbookStore.value.map_waypoints) // stringify to compare
        
        // Always update on 'discovery' mode, only when waypoints are different
        if(props.type == 'discovery' || cmpWapoints != mapWaypoints){
            cmpWapoints = mapWaypoints;
            calculating(true);
            let routeResult:any = emptyResponse;
            if(props.type == 'discovery'){
                routeResult = await calculateRoundtrip(roadbookStore.value.map_waypoints, roadbookStore.value.roundtrip_heading, roadbookStore.value.roundtrip_distance, roadbookStore.value.roundtrip_seed);
            }
            else{
                routeResult = await calculateRoute(roadbookStore.value.map_waypoints);
            }
            response.value = routeResult;
            roadbookStore.value.points = routeResult.points;
            roadbookStore.value.points_sections = routeResult.points_sections;
            roadbookStore.value.waypoints = routeResult.waypoints;
            roadbookStore.value.total_distance = routeResult.distance;
            roadbookStore.value.estimated_time = routeResult.time;
            roadbookStore.value.bbox = routeResult.bbox;
            calculating(false);
        }
    }

    watch(() => roadbookStore.value.map_waypoints, (newValue, oldValue) => {
        updateRouting()
    }, { deep: true });

    /*
        Calculate the route for given wayponts and return navigation points
        Return object :
        {
            "points": Array<Array<Number|Number>> // Lat/Long array
            "sections": Array<Array<Number|Number|String>> // array of start/stop index postion of profile ID
        }
    */
    async function calculateRoute(waypointsProfiled:any[]):Promise<any>{
        if( waypointsProfiled.length < 2){ // at least 2 steps to calculate
            return emptyResponse;
        }

        let ghResponse:any = await graphhopperAPI.route({
            "waypoints_profiled": waypointsProfiled,
            "roadbook_id": roadbookStore.isNew() ? null:roadbookStore.id
        })

        if(ghResponse.success == false) ghResponse = emptyResponse
        
        return ghResponse;
    }

    async function calculateRoundtrip(waypoints:any[], heading:number, distance:number, seed:number=0):Promise<any>{
        if( waypoints.length < 1){ // at least 1 steps to calculate
            return emptyResponse;
        }

        let ghResponse:any = await graphhopperAPI.routeDiscovery({
            profile_id: roadbookStore.currentProfile(),
            distance: distance,
            heading: heading,
            seed: seed,
            departure: waypoints[0],
        })

        if(ghResponse.success == false) ghResponse = emptyResponse

        return ghResponse;
    }
</script>

<style scoped></style>