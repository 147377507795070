<template>
    <LocationSelector ref="departureRef" :currentLocationAsDefault="true"
    :title="$t('Departure')" title-icon="/start.svg" @locationSelected="departure = $event; route()"></LocationSelector>

    <div v-if="addedSteps.length"  >
        <LocationSelector  v-for="(step, index) of addedSteps" :title="$t('Step') + ` ${index+1}`" :key="index" ref="stepsRef" @remove="stepsRef.splice(index, 1);console.log(addedSteps, index, addedSteps.splice(index, 1))"
            removeButton title-icon="/step.svg" @locationSelected="steps.push($event);route()"></LocationSelector>
    </div>


    <LocationSelector  ref="destinationRef"
    :title="$t('Destination')" title-icon="/stop.svg" @locationSelected="destination = $event; route()"></LocationSelector>

    <ion-row v-if="addedSteps.length < 3">
        <ion-col size="12">
            <ion-button @click="addedSteps.push(addedSteps.length)" fill="outline" expand="block"  class="ion-text-start add-step">
                <MaterialSymbol name="add_box" class="ion-margin-end"></MaterialSymbol>
                {{ $t('Add a step') }}
            </ion-button>
        </ion-col>
    </ion-row>
    

    <RouteProfileSelector class="ion-margin" :options="false" :profile="roadbookStore.currentProfile()" @changed="roadbookStore.setStartProfile($event)"></RouteProfileSelector>

    <Router ref="routing" :type="roadbookType"></Router>

</template>

<script setup lang="ts">
    import { ref, onMounted, Ref } from 'vue';
    import RouteProfileSelector from '@/components/RouteProfileSelector.vue';
    import LocationSelector from '@/components/LocationSelector.vue';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import Router from '@/components/Router.vue';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';

    // HTML refs
    const routing = ref();
    const optionModal = ref();
    const departureRef = ref();
    const stepsRef = ref();
    const destinationRef = ref();
    // variables refs
    const departure:any = ref(undefined);
    const destination:any = ref(undefined);
    const steps:any = ref([]);
    const addedSteps:Ref<number[]> = ref([]);
    const roadbookType ='flash'
    // Stores
    const roadbookStore = roadbookEditStore()


    onMounted(()=>{
        roadbookStore.init('new', roadbookType);
        roadbookStore.options.automatic_feet = true;
    });

    function route(){
        if(!departure.value || !destination.value) return

        let waypoints:string[] = [departure.value]

        for(let step of steps.value){
            waypoints.push(step)
        }
        waypoints.push(destination.value)

        roadbookStore.clear();
        for(let wp of waypoints){
            roadbookStore.addWaypoint(wp)
        }

        flashRoute()
    }  


    function flashRoute(){
        routing.value.reset();
        routing.value.updateRouting();
    }

</script>

<style scoped>
    ion-modal {
        --height: 55%;
        --border-radius: 16px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .modal-section {
        font-size: 20px;
    }

    .search-card {
        margin: 0 0 0 0;
    }

    .toolbar-title {
        text-overflow: unset !important;
    }

    ion-chip.chip-outline {
        background-color: var(--ion-color-success) !important;
        color: white
    }

    ion-content ion-card {
        box-shadow: none;
    }

    ion-segment { 
        width: 100%;
    }

    ion-button.add-step {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 0.8rem;;
        --border-radius: 10px;
        text-align: left !important;
    }
    ion-button.add-step button{
        margin-top: 0px !important;
        padding-top: 0px !important;
    }

    ion-footer {
        border-top: 2px var(--ion-color-petrol) solid !important;
    }
</style>