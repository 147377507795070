<template>
       <ion-content :fullscreen="true">
        <ion-row>
          <ion-col size="6">
            <ion-row id="card-menu">
                <ion-col size="3" class="ion-no-padding">
                    <ion-card id="open-new-trip" color="petrol" button fill="outline" @click="newTripModal.$el.present()">
                        <ion-card-content class="ion-text-center">
                            <ion-text color="light">
                                <MaterialSymbol name="add_location_alt" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Create a trip')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
                <ion-col size="3" class="ion-no-padding">
                    <ion-card id="open-new-trip" color="petrol" button fill="outline" router-link="/community">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <MaterialSymbol name="signpost" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Explore')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <ion-col size="3" class="ion-no-padding">
                    <ion-card id="open-new-trip" color="petrol" button fill="outline" router-link="/roadbooks">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <MaterialSymbol name="route" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Roadbooks')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <ion-col size="3" class="ion-no-padding">
                    <ion-card id="open-new-trip" color="petrol" button fill="outline" router-link="/help">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <MaterialSymbol name="info" class="home-icon" color="primary"></MaterialSymbol>
                                <div>
                                    <ion-text><b>{{$t('Help center')}}</b></ion-text>
                                </div>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

           

                <ion-col v-if="!authStore.isLoggedIn"  size="12" class="ion-padding ion-text-center">
                    <ion-button fill="outline" size="medium" expand="block" router-link="/login">
                        <MaterialSymbol slot="start" class="ion-margin-end" name="account_circle"></MaterialSymbol>
                        {{$t('Login')}}
                    </ion-button>
                    <ion-text color="primary" >
                        {{$t('create an account for more features')}}
                    </ion-text>
                </ion-col>

              <ion-col size="12"v-if="lastEditedRoadbooks && lastEditedRoadbooks.length">
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>{{ $t("Last activities") }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-list>
                            <ion-item v-for="roadbook of lastEditedRoadbooks" button :router-link="'/roadbooks/'+roadbook.id+'/edit/'+roadbook.type">
                                <ion-icon :icon="roadbook.roundtrip ? reloadOutline:analyticsOutline" color="tertiary"></ion-icon>
                                <ion-text class="ion-margin-start ion-margin-end">{{ roadbook.title }}</ion-text>
                                <!-- <ion-chip color="primary" mode="ios">
                                    <ion-label>{{$t(roadbook.type)}}</ion-label>
                                </ion-chip> -->
                            </ion-item>
                        </ion-list>
                    </ion-card-content>
                </ion-card>
            </ion-col>
          </ion-row>
          </ion-col>
          <ion-col push="2" size="4">
                <ion-card button class="shop-gps" href="https://www.tripy.eu" target="_blank">
                    <ion-card-content>
                        <img src="/buy-tripy.png">
                        <div class="div-center ion-text-center">
                            <ion-card>
                                <ion-card-header>
                                    <ion-card-title><ion-text color="primary" class="bold"><h1><b>{{$t('Buy a Tripy GPS')}}</b></h1></ion-text></ion-card-title>
                                    <ion-card-subtitle>{{$t('Access the online store')}}</ion-card-subtitle>
                                </ion-card-header>
                            </ion-card>
                        </div>
                    </ion-card-content>
                </ion-card>
          </ion-col>
        </ion-row>

        <TripModal ref="newTripModal"></TripModal>

        <div class="user-links">
            <ion-text color="medium" class="ion-margin-end">
            <a href="/confidentality">Privacy policy</a>
            </ion-text>
            <ion-text color="medium">
            <a href="/conditions-of-use">Terms of service</a>
            </ion-text>
        </div>
    </ion-content>

</template>

<script setup lang="ts">
  import { analyticsOutline, reloadOutline } from 'ionicons/icons';
  import { MaterialSymbol } from '@dbetka/vue-material-symbols';
  import { BackendAPI } from '@/libs/backend';
  import { ref, onUpdated, onMounted } from 'vue'
  import { refreshData } from '@/libs/userExperience'
  import { useRouter } from 'vue-router';
  import TripModal from '@/components/TripModal.vue';
  import { AuthStore } from '@/stores/AuthStore';
 

  const lastEditedRoadbooks:any = ref([])
  const roadbookAPI = new BackendAPI('/roadbook')
  const router = useRouter();
  const newTripModal = ref()
  const authStore = AuthStore();

  // TODO : from backend + Pinia for local storage
  var userSettings = ref({"language":"fr"})

  onUpdated(()=>{
    refreshData(fetchRoadbooks)
  });

  onMounted(()=>{
    fetchRoadbooks()
  });

  async function fetchRoadbooks(){
    lastEditedRoadbooks.value = await roadbookAPI.all({limit:3, sorting:['-updated_date']});
  }

  function createRoadbook(type:string){
    router.push({path: `/roadbooks/prepare/${type}`})
  }

</script>


<style scoped>
  ion-toolbar ion-title {
    text-align: center;
  }



  #menu-logo {
        margin-top: 70%;
    }

    .trip-button {
        border-radius: 10px;
        border: 1px var(--ion-color-light) solid;
        font-size: 25px;
    }

    .menu-button {
        border-radius: 10px;
        --background: none;
    }

    .home-icon {
        font-size: 3em !important;
    }

    ion-content {
            --background: none;
            background-image: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(var(--ion-color-anthracite-rgb), 1) 50%), url("/backgrounds/dashboard.jpg")  !important;
            background-size: 100% auto !important;
            background-position: top center !important;
            background-repeat: no-repeat !important; 

    }
    .user-links {
        position: absolute;
        bottom: 10px;
        text-align: center;
        width: 100%;
        font-size: 11px;
    }
</style>